import "core-js/stable";
import "regenerator-runtime/runtime";
import "@babel/polyfill";
import Es6Promise from "es6-promise";
require("es6-promise").polyfill();
Es6Promise.polyfill();

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "lib-flexible";
import axios from "axios";
Vue.use(ElementUI);

Vue.prototype.$axios = axios;
Vue.prototype.$message = Message;
//  http://inscription.zyrkj.cn/api.php/
// axios.defaults.baseURL = 'https://inscription.zyrkeji.cn/api.php'
axios.defaults.baseURL = "https://www.ticiwang.cn/api.php";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
