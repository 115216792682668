<template>
	<div class="home" @mousemove="mousemoveE" @mouseup="mouseupE" @mouseleave="mouseleave2">
		<div class="nav-bottom"></div>

		<!-- banner -->
		<!-- <div class="banner">
      <img src="../assets/images/banner.png" alt="" />
    </div> -->

		<el-carousel class="banner" height="240px" :interval="7000">
			<el-carousel-item v-for="(item, index) in bannerList" :key="index" height="240px">
				<img :src="item.image.file_path" alt="" />
			</el-carousel-item>
		</el-carousel>

		<div class="content">
			<img class="content-qrCode" src="@/assets/images/qrCode.jpg" alt="">
			<div class="content-left">
				<div class="content-left-btn" @click="changeStatusSub(1)" :class="{ active: status_sub == 1 }">
					我的文案
				</div>
				<div class="content-left-btn" @click="changeStatusSub(2)" :class="{ active1: status_sub == 2 }">
					回收站
				</div>
			</div>

			<div class="content-hr" style="margin-left: 4%; margin-right: 4%"></div>

			<div class="content-center">
				<!--  -->
				<div class="content-center-header" v-if="status_sub == 1">
					<div class="content-center-header-left" @click="createDocument()">
						创建文案
					</div>
					<div class="content-center-header-right">
						<div class="r1" @click="showPopup()">上传文案</div>
						<div class="r2"></div>
						<div class="r1" @click="downDocumentD()">下载文案</div>
					</div>
				</div>
				<!--  -->
				<div class="content-center-t2" v-if="status_sub == 1">
					提词王 | 在线提词器
				</div>
				<!--  -->
				<div class="content-center-t3">
					<div class="t3-left">文案列表</div>
					<div @click="choice_All()" class="t3-left puit_all shibxs" v-if="multi_select">
						<div v-if="!is_choice" class="list-left" :class="{ active: is_choice }"></div>
						<img class="list-left-2" v-else-if="is_choice" src="../assets/images/gx.png" alt="" />
						<span>全部</span>
					</div>
					<div class="t3-right" :class="{ acs: multi_select }" @click="duoxuan">
						{{ multi_select ? "退出" : "" }}多选
					</div>
				</div>

				<div class="content-center-t4">
					<input v-model="search" class="t4-1" placeholder="搜索文案" @input="onSearch()" />
					<div @click="changeIsBiaoq()">
						<img v-if="tag_type == 1" class="t4-2" src="../assets/images/biaoq_jj.png" />
						<img v-else-if="tag_type == 2" class="t4-2" src="../assets/images/biaoq_dd.png" />
						<img v-else-if="tag_type == 3" class="t4-2" src="../assets/images/biaoq_wc.png" />
						<img v-else class="t4-2" src="../assets/images/t4-1.png" />
					</div>
					<img @click="refr()" class="t4-3" src="../assets/images/t4-2.png" />
					<div class="xialatc" style="right: 15%; top: 40px" v-if="is_biaoq">
						<!-- "tag_type": 1 //标签类型 0无 1紧急 2等待 3完成 -->
						<div class="xuanx" @click="changeTag(1)">
							<span>紧急</span>
							<img class="xuanximg" src="../assets/images/biaoq_jj.png" />
						</div>
						<div class="xuanx" style="margin: 12px 0px" @click="changeTag(2)">
							<span>等待</span>
							<img class="xuanximg" src="../assets/images/biaoq_dd.png" />
						</div>
						<div class="xuanx" @click="changeTag(3)">
							<span>完成</span>
							<img class="xuanximg" src="../assets/images/biaoq_wc.png" />
						</div>
					</div>
				</div>

				<div class="content-center-t5" v-if="vipNotice.is_show == 1">
					{{ vipNotice.msg }}
				</div>

				<!-- <div class="" @click="handleScrollTop">顶部</div>
        <div class="" @click="handleScrollBottom">底部</div> -->
				<!-- <div class="list-content-block">
          <el-slider
            v-model="sliderValue"
            vertical
            height="460px"
            :show-tooltip="true"
          >
          </el-slider>
        </div> -->
				<div class="list-content" ref="scrollView" id="scroll-view" @scroll="handleScroll"
					:class="{ shibxs: multi_select }" style="">
					<!-- popup-3 -->
					<div class="documentSC-popup" v-if="documentSC">
						<img class="sc-img" src="../assets/images/sz.png" alt="" />
						<div class="sc-text">{{ documentSCTitle }}</div>
					</div>
					<div class="content-center-list" :class="{
              'content-center-listb': item.choose,
              margin0: index == 1,
              'content-center-listb2': item.choose && status_sub == 2,
            }" v-for="(item, index) in official_list" :key="index" @click="choice(item, index)">
						<div class="list-left el-icon-check" v-if="multi_select && !item.choose"></div>
						<img class="list-left-2" src="../assets/images/gx.png" v-if="multi_select && item.choose"
							alt="" />
						<div class="list-center">
							<div class="c1">
								<div class="c1-1">{{ item.title }}</div>
								<img class="c1-2" @click="changeXiala(index)" @mouseenter="changeXiala(index)"
									v-if="item.tag_type == 1" src="../assets/images/biaoq_jj.png" />
								<img class="c1-2" @click="changeXiala(index)" @mouseenter="changeXiala(index)"
									v-else-if="item.tag_type == 2" src="../assets/images/biaoq_dd.png" />
								<img class="c1-2" @click="changeXiala(index)" @mouseenter="changeXiala(index)"
									v-else-if="item.tag_type == 3" src="../assets/images/biaoq_wc.png" />
								<div v-else>
									<img v-if="item.id" class="c1-2" @click="changeXiala(index)"
										@mouseenter="changeXiala(index)" src="../assets/images/sc.png" />
								</div>

								<div class="dingwzs" v-if="item.is_xiala && !multi_select"
									@mouseleave="changeXiala(index)">
									<!-- img  @click.stop="
                      item.is_xiala = false;
                      item.is_biaoq = false;
                    " -->
									<img src="../assets/images/exit.png" @click.stop="changeXiala(index)"
										style="width: 16px; height: 16px" />
									<div class="dingwzContent">
										<span @click="tabDocument(item, 1)">置顶</span>
										<span @click="tabDocument(item, 2)">置底</span>
										<span @click="deleteDocument(item)">删除</span>
									</div>
									<div class="dingwzContent" style="margin-top: 22px">
										<span @click="downDocument(item)">下载</span>
										<!--  @click.stop="item.is_biaoq = !item.is_biaoq" -->
										<div class="xiala" @click="changeBiaoq(index)">
											<span>标签</span>
											<img v-if="item.tag_type == 1" class="xiala-img"
												src="../assets/images/biaoq_jj.png" />
											<img v-else-if="item.tag_type == 2" class="xiala-img"
												src="../assets/images/biaoq_dd.png" />
											<img v-else-if="item.tag_type == 3" class="xiala-img"
												src="../assets/images/biaoq_wc.png" />
											<img v-else class="xiala-img" src="../assets/images/sc.png" />
											<img class="load-img" src="../assets/images/load.png"
												style="width: 14px; height: 6px" />
											<div class="xialatc" v-if="item.is_biaoq">
												<!-- "tag_type": 1 //标签类型 0无 1紧急 2等待 3完成 -->
												<div class="xuanx" @click="changeDocumentTag(item, 1)">
													<span>紧急</span>
													<img class="xuanximg" src="../assets/images/biaoq_jj.png" />
												</div>
												<div class="xuanx" style="margin: 12px 0px"
													@click="changeDocumentTag(item, 2)">
													<span>等待</span>
													<img class="xuanximg" src="../assets/images/biaoq_dd.png" />
												</div>
												<div class="xuanx" @click="changeDocumentTag(item, 3)">
													<span>完成</span>
													<img class="xuanximg" src="../assets/images/biaoq_wc.png" />
												</div>
											</div>
										</div>
										<div></div>
									</div>
								</div>
							</div>
							<div class="c2 cc-slh-2">
								{{ item.content }}
							</div>
							<div class="zzs">
								<div class="c3">总字数:{{ item.content.length }}</div>
							</div>
						</div>
						<div class="list-right shibxs" @click.stop="changedrawer(item.id,item)" type="primary"
							style="margin-left: 16px" v-if="status_sub == 1">
							打开题词器
						</div>
						<div class="list-right shibxs" type="primary" style="margin-left: 16px; background: #d9d9d9"
							v-else>
							打开题词器
						</div>
					</div>
					<!-- <div class="content-center-list" v-for="(item, index) in 2" :key="index">
            <div class="list-left el-icon-check" v-if="multi_select"></div>
            <div class="list-center">
              <div class="c1">
                <div class="c1-1">读书笔记</div>
                <img class="c1-2" src="../assets/images/sc.png" />
              </div>
              <div class="c2">
                我曾以为人生的苦难仅仅只是我所接触到的这一种，虽然理智告诉我还有
              </div>
              <div class="c3">总字数:25</div>
            </div>
            <div class="list-right" @click="drawer = true" type="primary" style="margin-left: 16px">
              打开题词器
            </div>
          </div> -->
				</div>

				<div class="moreChoose" v-if="multi_select && status_sub == 1">
					<div class="ch1" @click="downDocumentD()">批量下载</div>
					<div class="ch2" @click="duoxuan()">取消</div>
					<div class="ch3" @click="deleteDocumentD()">删除</div>
				</div>
				<div class="moreChoose" v-if="multi_select && status_sub == 2">
					<div class="ch1" @click="huanyuanD()" style="color: rgba(9, 154, 59, 1)">
						批量恢复
					</div>
					<div class="ch2" @click="duoxuan()">取消</div>
					<div class="ch3" @click="deleteDocumentD()">删除</div>
				</div>
			</div>

			<!-- 抽屉 -->
			<el-drawer title="我是标题" :visible.sync="drawer" :direction="direction" :with-header="false" open="openDrawer"
				size="100%" ref="navDrawer">
				<!-- { 'margin-top': `${ztTop3}` }, -->
				<!-- v-if="drawer" -->
				<div id="eldrawer" style="position: absolute" @click="monitorFors = false" @mouseover="mouseover"
					v-if="drawer" @mouseleave="mouseleave"
					:style="[{ height: `${drawerSize}` }, { width: `${drawerWidth}` }]">
					<div class="eldrawer">
						<div class="drawer_pos" :style="[
                { height: heights },
                { top: `${ztTop2} ` },
                { width: posWidth },
              ]"></div>
						<!-- img -->
						<img class="zt" id="zt" v-if="mouse && !isBf && bbf && countdown == 0" @click="changeBF('true')"
							src="../assets/images/p-stop.png" alt="" :style="[{ top: `${ztTop}` }]" />
						<img class="zt" id="zt" v-if="mouse && isBf && bbf && countdown == 0" @click="changeBF('false')"
							src="../assets/images/p-bf.png" alt="" :style="[{ top: `${ztTop}` }]" />

						<img class="zt" id="zt" v-if="bbf && bbf2 && countdown == 3" src="../assets/images/p3.png"
							alt="" :style="[{ top: `${ztTop}` }]" />

						<img class="zt" id="zt" v-if="bbf && bbf2 && countdown == 2" src="../assets/images/p2.png"
							alt="" :style="[{ top: `${ztTop}` }]" />

						<img class="zt" id="zt" v-if="bbf && bbf2 && countdown == 1" src="../assets/images/p1.png"
							alt="" :style="[{ top: `${ztTop}` }]" />
						<!-- @mouseup="mouseupE"
            @mouseleave="mouseleave2" -->
						<div class="drawer_top" @mousedown="mousedownE">
							<div @click="() => closeDrawer()" class="el-icon-close t-left" style="font-size: 36px">
							</div>
							<div @click="getPostHtml" :class="{
                  'el-icon-video-play': isBf,
                  'el-icon-video-pause': !isBf,
                }" class="t-left" style="font-size: 60px"></div>

							<img v-if="qqq" @click="changeqqq" src="../assets/images/qp.png" style="width: 36px"
								alt="" />
							<img v-else @click="changeqqq" style="width: 36px" src="../assets/images/qs.png" alt="" />
						</div>

						<div id="drawer" class="drawer" :style="[{ height: `${drawerConent}` }]">
							<div image.png class="drawer_conent" id="drawer_conent" :class="{ jinxiang: isJinxiang }"
								:style="[
                  {
                    'font-size': `${
                      setting.fontSize / (0.67 * 0.67) - (isMacNoSafari ? 0 : 0)
                    }px`,
                  },
                  { color: setting.color },
                  { background: setting.background },
                ]">
								<!-- :style="[{ height: heights + 'px' }]" -->
								<!-- @click.stop="monitorFors = true" -->
								<div class="drawer_post" id="drawer_post_s" :contenteditable="isBf"
									@click.stop="monitorFors = isBf" ref="drawer_post" :style="[
                    {
                      'padding-left': `${
                        setting.letterSpacing / (0.67 * 0.67)
                      }px`,
                    },
                    {
                      'padding-right': `${
                        setting.letterSpacing / (0.67 * 0.67)
                      }px`,
                    },
                    {
                      'padding-bottom': `${
                        qqq ? 1.3 * windowHeight : 1.8 * windowHeight
                      }px`,
                      'font-family': 'none !important',
                    },
                  ]" :class="{ opacity0: isBf, opacity1: !isBf }">
									<p :style="`padding:${
                      setting.fontSize - 20
                    }px 0;line-height:1.3`" :id="'trdit_' + index" v-for="(item, index) in contentArr" :key="index">
										{{ item }}
									</p>
								</div>
								<div v-if="chooseItem.content">
									<!-- <div
                    style="height: 50vh"
                    v-if="chooseItem.content.length < 500"
                  ></div>
                  <div style="height: 25vh"></div> -->

									<!-- <div
                    style="height: 46vh"
                    v-if="qqq"
                  ></div>
                  <div v-else style="height: 77vh"></div> -->
									<!-- <div style="height: 120vh" v-if="qqq"></div>
                  <div v-else style="height: 170vh"></div> -->
								</div>

								<!-- <div
                v-if="bbf && bbf2"
                class="zt"
                style="background: rgb(254, 113, 139); border-radius: 50%"
                :style="[{ top: `${ztTop}` }]"
              >
                {{ countdown ? countdown : "3" }}
              </div> -->
							</div>
						</div>

						<!--  -->
						<!-- <div class="block" style="padding: 20px">
            <span class="demonstration">滚动速度</span>
            <el-slider v-model="setting.speed" :min="1" :max="10"></el-slider>
          </div>
          <div class="block" style="padding: 20px">
            <span class="demonstration">文字大小</span>
            <el-slider
              v-model="setting.fontSize"
              :min="12"
              :max="40"
            ></el-slider>
          </div> -->
						<!-- <div class="block">
            <span class="demonstration">文字间距</span>
            <el-slider v-model="setting.speed"></el-slider>
          </div> -->
						<!--  -->
						<!-- 
          <div class="block">
            <span class="demonstration">有默认值</span>
            <el-color-picker v-model="setting.color"></el-color-picker>
          </div> -->

						<!-- 台词切换 -->
						<div class="draw-bottom-right" v-if="settingLeft">
							<div class="rightcontent">
								<div class="dr-h1">
									<div @click="changeSettingLeft()">关闭</div>
									<div>台词切换</div>
									<div @click="changeSettingLeft()">确定</div>
								</div>

								<div class="tc-h2">
									<div>台本标题</div>
									<div @click="reverseArr()">
										时间排序
										<i class="el-icon-caret-bottom" :class="{ re: rese }"></i>
									</div>
									<div>状态</div>
								</div>

								<div class="tc-h3">
									<!-- official_list -->
									<div class="tc-h3-list" v-for="(item, index) in official_list" :key="index"
										:class="{ active: chooseItem.id == item.id }" @click="choEscice(item, index)">
										<div class="l1">{{ item.title }}</div>
										<div class="l2" v-if="item.create_time">
											{{ Time(item.create_time) }}
										</div>
										<div class="l3" v-if="chooseItem.id == item.id">使用中</div>
										<div class="l3" v-else>等待中</div>
									</div>
								</div>
							</div>
						</div>

						<!-- 播放设置 -->
						<div class="draw-bottom-right" v-if="settingRight">
							<div class="rightcontent">
								<div class="dr-h1">
									<div @click="changeSettingRight()">关闭</div>
									<div>播放设置</div>
									<div @click="changeSettingRight()" style="padding-right: 280px">
										确定
									</div>
								</div>

								<!-- dr-h2 -->
								<div class="dr-h2">
									<div class="dr-h2-left">
										<div class="switch-title">匀速播放</div>
										<el-switch v-model="isYunsu" active-color="rgb(254,67,101)"
											inactive-color="rgb(233,229,229)">
											<!-- @change="changeHuanyuan2" -->

										</el-switch>
									</div>
									<div class="dr-h2-right">
										<div class="color-title">文字颜色</div>
										<div class="color-choose" @click="changeFontColor('rgba(254, 67, 101, 1)')"
											style="background: rgba(254, 67, 101, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(41, 51, 60, 1)')"
											style="background: rgba(41, 51, 60, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(255, 127, 0, 1)')"
											style="background: rgba(255, 127, 0, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(255, 242, 0, 1)')"
											style="background: rgba(255, 242, 0, 1)"></div>
										<div class="color-choose" @click="changeFontColor(' rgba(17, 147, 82, 1)')"
											style="background: rgba(17, 147, 82, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(3, 115, 138, 1)')"
											style="background: rgba(3, 115, 138, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(26, 67, 155, 1)')"
											style="background: rgba(26, 67, 155, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(26, 67, 155, 1)')"
											style="background: rgba(26, 67, 155, 1)"></div>
										<div class="color-choose" @click="changeFontColor('rgba(255, 255, 255, 1)')"
											style="background: rgba(255, 255, 255, 1)"></div>
									</div>
								</div>

								<!-- dr-h2 -->
								<div class="dr-h2">
									<div class="dr-h2-left">
										<div class="switch-title">镜像模式</div>
										<el-switch v-model="isJinxiang" active-color="rgb(254,67,101)"
											inactive-color="rgb(233,229,229)" @change="changeJinxiang">
										</el-switch>
									</div>
									<div class="dr-h2-right">
										<div class="color-title">背景颜色</div>
										<div class="color-choose" @click="changeBackground('rgba(254, 67, 101, 1)')"
											style="background: rgba(254, 67, 101, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(41, 51, 60, 1)')"
											style="background: rgba(41, 51, 60, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(255, 127, 0, 1)')"
											style="background: rgba(255, 127, 0, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(255, 242, 0, 1)')"
											style="background: rgba(255, 242, 0, 1)"></div>
										<div class="color-choose" @click="changeBackground(' rgba(17, 147, 82, 1)')"
											style="background: rgba(17, 147, 82, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(3, 115, 138, 1)')"
											style="background: rgba(3, 115, 138, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(26, 67, 155, 1)')"
											style="background: rgba(26, 67, 155, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(26, 67, 155, 1)')"
											style="background: rgba(26, 67, 155, 1)"></div>
										<div class="color-choose" @click="changeBackground('rgba(255, 255, 255, 1)')"
											style="background: rgba(255, 255, 255, 1)"></div>
									</div>
								</div>

								<!-- dr-h3 -->
								<div class="dr-h3">
									<div class="h3-title">滚动速度</div>
									<div class="h3-slider">
										<el-slider v-model="setting.speed" :min="1" :max="50"
											:show-tooltip="false"></el-slider>
									</div>
									<div class="h3-tip">{{ setting.speed }}</div>
								</div>

								<div class="dr-h3">
									<div class="h3-title">文字大小</div>
									<div class="h3-slider">
										<el-slider v-model="setting.fontSize" :min="12" :max="100"
											:show-tooltip="false"></el-slider>
									</div>
									<div class="h3-tip">{{ setting.fontSize }}</div>
								</div>

								<div class="dr-h3">
									<div class="h3-title">文字边距</div>
									<div class="h3-slider">
										<el-slider v-model="setting.letterSpacing" :min="1" :max="290"
											:show-tooltip="false"></el-slider>
									</div>
									<div class="h3-tip">{{ setting.letterSpacing }}</div>
								</div>
							</div>
						</div>

						<!-- <div style="height: 130px; background: rgb(237, 237, 237)"></div> -->

						<div class="drawer_bottom" v-if="!qqq">
							<div class="btn1" @click="changeSettingLeft()">文案切换</div>
							<div class="btn2" @click="changeSettingRight()">播放设置</div>
						</div>
					</div>
				</div>
			</el-drawer>
			<div class="content-hr" style="margin-left: 4%; margin-right: 4%"></div>

			<div class="content-right" style="">
				<div class="content-right-h1">
					<div class="h1-left">创作台词</div>
					<div class="h1-right" v-if="status_sub == 1">
						<div v-if="chooseItem" class="h1-right-download" @click="downDocumentWD()">
							<img src="../assets/images/download.png" alt="" />
							<div>下载文案</div>
						</div>
						<div class="h1-right-up" @click="addDocuments()">
							<img src="../assets/images/up.png" alt="" />
							<div>保存</div>
						</div>
					</div>

					<!-- 回收站 -->
					<div class="h1-right" v-if="status_sub == 2">
						<div class="hs-btn1" @click="huanyuan()">还原</div>
						<div class="hs-btn2" @click="deleteDocumentHS()">删除</div>
					</div>
				</div>

				<div class="content-right-h2">
					标题
					<span>限16字</span>
				</div>

				<div @click="changePen()" class="content-right-h3" v-if="!isPen" v-html="chooseItem.title"></div>
				<input v-model="title" class="content-right-h3" placeholder="我的第一句台词" maxlength="16" v-else />

				<div class="content-right-h4">
					<div class="h4-left">
						<div class="tc">台词</div>
						<div class="gz" v-if="chooseItem">
							共{{ chooseItem.content.length }}字
						</div>
					</div>
					<!--  v-if="status_sub == 1 && !isPen" -->
					<div class="h4-right" @click="detectDocuments()" v-if="status_sub == 1">
						违规词检测
					</div>
					<!-- <div class="h4-right" style="background: #d9d9d9" @click="detectDocuments()" v-else>
            违规词检测
          </div> -->
				</div>

				<div class="content-right-textarea" v-if="!isPen" @click="changePen()">
					<div class="tar" style="overflow-y: scroll" v-if="chooseItem.content2" v-html="chooseItem.content2">
					</div>
					<div class="tar" style="overflow-y: scroll" v-else v-html="chooseItem.content"></div>
					<img v-if="status_sub !== 2" @click="changePen" class="choosePen" src="../assets/images/pen.png" />
				</div>

				<div style="position: relative" v-else>
					<textarea v-model="content" class="content-right-textarea"></textarea>
				</div>
			</div>
		</div>

		<a href="" id="ahref"></a>

		<div v-show="popup && this.$store.state.user">
			<!-- 1 -->
			<div class="upload-popup" v-if="popupType == 'upload'">
				<div class="upload-pp" v-if="exceed" style="
            padding: 10px 15px;
            background: #fe4365;
            border-radius: 21px 21px 21px 21px;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display:flex;
            align-items: center;
            justify-content: center
           position: absolute !important;
            left: 50%;
            transform: translateX(-50%);
            top: -13.5%;
          ">
					提示：上传的文档数量已超过6个，请分批次
				</div>
				<img class="upload-popup-exit" @click="closePopup" src="../assets/images/exit.png" />
				<div class="upload-popup-h1">上传文件</div>

				<el-upload ref="upload" v-loading="loading" :multiple="true" class="upload-demo"
					action="https://www.ticiwang.cn/api.php/documents/upload" name="iFile[]" :auto-upload="false"
					:headers="{ token: JSON.parse(this.$store.state.user).token }" :on-success="handleAvatarSuccess"
					:on-exceed="handleExceed" :limit="6" :before-upload="beforeAvatarUpload"
					v-if="this.$store.state.user">
					<div class="upload-popup-h2">
						<img class="h2-1" src="../assets/images/upload.png" alt="" />
						<div class="h2-2">点击上传文件</div>
						<div class="h2-3">
							温馨提示：目前仅支持内容为纯文本的单/多文档上传，每批次上传上限为6个文档
						</div>
					</div>
				</el-upload>

				<div class="upload-popup-btn">
					<div class="btn1" @click="closePopup()">取消</div>
					<div class="btn2" @click="submitUpload()">上传</div>
				</div>
			</div>

			<!-- popup-2 -->
			<div class="documentAdd-popup" v-if="popupType == 'documentAdd'">
				<img class="documentAdd-popup-exit" @click="closePopup" src="../assets/images/exit.png" />
				<!-- <div class="documentAdd-popup-h1">上传文件</div> -->

				<div class="documentAdd-popup-h2">创建文案的数量已达上限</div>
				<div class="documentAdd-popup-h3">
					尊敬的用户，您的文案创建的数量已达到<span style="color: rgb(254, 67, 101)">6</span>个的上限，开通会员即可享受不限数量的题词服务。
				</div>

				<div class="documentAdd-popup-btn">
					<div class="btn1" @click="closePopup">取消</div>
					<div class="btn2" @click="navigateMember">立即开通</div>
				</div>
			</div>

			<div class="over" @click="closePopup"></div>
		</div>

		<div v-show="popup2">
			<!--手机登录-->
			<div class="login-popup" v-if="popupType2 == 'phone'">
				<img class="login-popup-exit" @click="closePopup2" src="../assets/images/exit.png" />
				<div class="login-popup-h1">手机密码登录</div>
				<div class="login-popup-h2">
					<div class="h2-top">
						<input type="text" v-model="phone" placeholder="请输入手机号码" maxlength="11"
							:input="phoneTestTrim()" />
						<img v-if="phoneTest" src="../assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
					</div>
					<div class="h2-bottom">
						<input type="password" v-model="password" placeholder="请输入手机密码" />
					</div>
				</div>
				<div class="login-popup-h3">
					<div class="h3-left" @click="changeType('password')">忘记密码？</div>
					<div class="h3-right" @click="changeType('yzm')">手机验证码登录</div>
				</div>

				<div class="login-popup-h4">
					<div class="h4-left" @click="changeFree()">
						<div class="el-icon-check" v-if="is_free == 1"></div>
					</div>
					<div class="h4-right">7天内免登录</div>
				</div>

				<div class="login-popup-h5" @click="phonePasswordLogin()">立即登录</div>
				<div class="login-popup-h6">
					点击立即登录按钮视为阅读并同意
					<span @click="navigateRouter('XPay')">《用户协议》</span>及<span
						@click="navigateRouter('XPrivacy')">《隐私政策》</span>
				</div>
			</div>

			<!--修改密码-->
			<div class="password-popup" v-else-if="popupType2 == 'password'">
				<img class="password-popup-exit" @click="closePopup2" src="../assets/images/exit.png" />
				<div class="password-popup-h1">修改密码</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input type="text" placeholder="请输入手机号码" maxlength="11" v-model="phone" :input="phoneTestTrim()" />
					<img v-if="phoneTest" src="../assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="code" type="text" placeholder="请输入验证码" />
					<div class="yzm" @click="getCode()" :class="{ disabled: disabled }">
						{{ countdown ? "重新获取 " + countdown : "获取验证码" }}
					</div>
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="password" type="password" placeholder="请输入新密码，字母与数字组合不少于8位" />
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="repassword" type="password" placeholder="请确认新密码" />
				</div>
				<div class="password-popup-btn" @click="changePassword()">修改</div>
			</div>

			<!--验证码登录 默认-->
			<div class="login-popup" v-if="popupType2 == 'yzm'">
				<img class="login-popup-exit" @click="closePopup2" src="../assets/images/exit.png" />
				<div class="login-popup-h1">手机验证码快捷登录</div>
				<div class="login-popup-h2">
					<div class="h2-top">
						<input type="text" v-model="phone" placeholder="请输入手机号码" maxlength="11"
							:input="phoneTestTrim()" />
						<img v-if="phoneTest" src="../assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
					</div>
					<div class="h2-bottom">
						<input type="text" v-model="code" placeholder="请输入验证码" />
						<div class="h2-bottom-btn" @click="getCode()" :class="{ disabled: disabled }">
							{{ countdown ? "重新获取 " + countdown : "获取验证码" }}
						</div>
					</div>
				</div>
				<div class="login-popup-h3">
					<div class="h3-left" @click="changeType('password')"></div>
					<div class="h3-right" @click="changeType('phone')">密码登录</div>
				</div>

				<div class="login-popup-h4">
					<div class="h4-left" @click="changeFree()">
						<div class="el-icon-check" v-if="is_free == 1"></div>
					</div>
					<div class="h4-right">7天内免登录</div>
				</div>

				<div class="login-popup-h5" @click="phoneCodeLogin">立即登录</div>
				<div class="login-popup-h6">
					点击立即登录按钮视为阅读并同意<span @click="navigateRouter('XPay')">《用户协议》</span>及<span
						@click="navigateRouter('XPrivacy')">《隐私政策》</span>
				</div>
			</div>

			<!--这里是半透明背景层-->
			<div class="over" @click="closePopup2"></div>
		</div>
	</div>
</template>

<script>
	// console.log = ()=>{};
	import Vue from "vue";
	import {
		mapGetters,
		mapActions
	} from "vuex";
	import tttci from "@/components/tttci.vue";
	export default {
		components: {
			tttci
		},
		name: "app",
		data() {
			return {
				windowHeight: 0,
				monitorFors: false,
				qqq: false,
				userinfo: localStorage.getItem("userinfo"),
				isLogin: localStorage.getItem("token"),
				popup: false,
				popupType: "upload",
				popup2: false,
				popupType2: "yzm",
				// codeTime
				countdown: 0,
				disabled: false,
				// form
				phone: "",
				password: "",
				code: "",
				is_free: "0",
				repassword: "",
				// test
				phoneTest: "",

				drawer: false,
				direction: "btt",
				drawerSize: "100%",
				drawerWidth: "100%",
				posWidth: "calc(100%-40px)",
				drawerConent: "calc(100% - 100px)",
				// 文案或者回收站
				status_sub: 1,
				multi_select: false,
				official_listNo: [],
				official_list: [
					// {
					//   id: 1,
					//   title: "读书笔记",
					//   content:
					//     "我曾以为人生的苦难仅仅只是我所接触到的这一种，虽然理智告诉我还有",
					//   choose: false,
					//   is_top: false,
					//   is_bottom: false,
					//   is_xiala: false,
					//   is_biaoq: false,
					// },
					// {
					//   id: 2,
					//   title: "读书笔记",
					//   content:
					//     "我曾以为人生的苦难仅仅只是我所接触到的这一种，虽然理智告诉我还有",
					//   choose: false,
					//   is_top: false,
					//   is_bottom: false,
					//   is_xiala: false,
					//   is_biaoq: false,
					// },
				],
				// 是否全选
				is_choice: false,
				heights: "10%",
				times: "",
				// form
				page: 1,
				content: "",
				content2: "",
				title: "",
				//节流阀
				isLoading: false,
				list: [],
				aaa: "true",
				loading: false,
				// 标签类型
				tag_type: "",
				is_biaoq: false,
				search: "",
				// 被选中的
				chooseItem: "",
				isPen: true,
				// 设置
				setting: {
					speed: 13,
					speed2: 13,
					fontSize: 55,
					color: "rgb(255, 127, 0)",
					background: "rgb(41, 51, 60)",
					letterSpacing: 100,
				},
				sliderValue: 100,
				// 弹窗设置
				settingLeft: false,
				settingRight: false,
				isYunsu: false,
				isJinxiang: false,
				mouse: false,
				isBf: true,
				keyWordArr: [],
				rese: false,
				documentSC: false,
				documentSCTitle: "操作成功",
				bannerList: [],
				exceed: false,
				ztTop: "50%",
				ztTop2: "120px",
				ztTop3: "0",
				bbf: false,
				bbf2: false,
				countdown: 0,
				disabled: false,

				down: false,
				dx: 0,
				dy: 0,
				sx: 0,
				sy: 0,
				vipNotice: {
					is_show: 0,
					msg: "",
				},
				mmmm: 0,
				contentArr: [],
				isMacNoSafari: false,
			};
		},
		watch: {
			"setting.fontSize"(val) {
				const drawer_post = this.$refs.drawer_post.childNodes;
				drawer_post.forEach((item) => {
					item.style = `padding: ${val * 0.6}px 0;line-height:1.3`;
				});
			},
			// "setting.speed": {
			//   handler(val) {
			//     if (val === 20) {
			//       this.isYunsu = true;
			//     } else {
			//       this.isYunsu = false;
			//     }
			//   },
			//   immediate: true,
			// },
			// deep:true,
			// 'setting.speed'() {
			//     this.setterDocument()
			// },
			// 'setting.speed2'() {
			//     this.setterDocument()

			// },
			// 'setting.fontSize'() {
			//     this.setterDocument()

			// },
			// 'setting.color'() {
			//     this.setterDocument()

			// },
			// 'setting.background'() {
			//     this.setterDocument()

			// },
			// 'setting.letterSpacing'() {
			//     this.setterDocument()
			// },

			// 画板
			drawer(value) {
				// if(value){
				//   document.getElementsByTagName("body")[0].style.zoom = 1;
				// }else{
				//   document.getElementsByTagName("body")[0].style.zoom = 0.67 * 0.67;
				// }
				console.error(value,this.times)
				
				
				if (this.times) {
					clearInterval(this.times);
					this.isBf = true;
					if (document.getElementById("drawer_conent"))
						document.getElementById("drawer_conent").scrollTop = 0;
					this.mmmm = 0;
				}
				if (value) {
					const seepDtime = 10;
					this.times = setInterval(() => {
						const drawer_conentDome = document.getElementById("drawer_conent");
						
						if (this.isBf || this.countdown !== 0) {
							
							return;
						}
						const scrollTop =
							drawer_conentDome.scrollTop || window.pageYOffset || 0;
						if (this.startBackTop) {
							console.error("在这里出发1:" + this.startBackTop,scrollTop);
							this.awaitTime += seepDtime;
							if (scrollTop <= 0) {
								
								drawer_conentDome.scrollTop = 0;
								this.isBf = true;
								this.mmmm = 0;
								this.startBackTop = false;
							} else if (this.awaitTime >= 300) {
								
								drawer_conentDome.scrollTop = 0;
								this.isBf = true;
								this.mmmm = 0;
								this.startBackTop = false;
								return
								
								drawer_conentDome.scrollTop -= this.awaitSeep;
								console.error("在这里出发2:" + drawer_conentDome.scrollTop);
							}
							return;
						}

						// console.error(scrollTop,'~',this.startBackTop);
						if (
							drawer_conentDome.scrollHeight -
							drawer_conentDome.clientHeight -
							scrollTop <
							20
						) {
							this.awaitTime = 0;
							this.awaitSeep = scrollTop / (seepDtime * 3);
							this.startBackTop = true;
						} else {
							// console.error("a:" + this.setting.speed);
							if (this.setting.speed) {
							/* 	console.error("b:" + this.setting.speed);
								console.error("z:" + typeof this.setting.speed); */
								// alert("c:"+ drawer_conentDome.scrollTop);
								// let speed = parseInt(this.setting.speed) * 0.5;
								// drawer_conentDome.scrollTop += speed  + 1;
								//  if(drawer_conentDome.scrollTop < 10) {
								//     drawer_conentDome.scrollTop = 100;
								//  } else {
								//   drawer_conentDome.scrollTop += 1;
								//   console.log('m', document.getElementById("drawer").scrollHeight)
								//   console.log('mm', drawer_conentDome.scrollTop)
								//  }
								let {
									speed
								} = this.setting;
								if (speed < 10) {
									speed = 10 - (10 - speed) * 0.1;
								}
								this.mmmm +=
									speed * (this.isMacNoSafari ? 0.08 : 0.08) -
									(speed / 50) * (speed > 39 ? (50 - speed) * 0.15 : 2);
								drawer_conentDome.scrollTop = this.mmmm;
								// window.onmousewheel = document.onmousewheel=function() {return false}

								// console.log('ccc', ccc)
								// drawer_conentDome.scrollTop += 1 + 1;
							} else {
								// alert("d:" + this.setting.speed);
								// alert("e:" + drawer_conentDome.scrollTop);
								console.log(
									"drawer_conentDome.scrollTop",
									drawer_conentDome.scrollTop
								);
								drawer_conentDome.scrollTop += 1;
							}
						}
					}, seepDtime);
				} else {
					this.times && clearInterval(this.times);
					this.settingRight = false;
					this.settingLeft = false;
				}
			},
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.resizeFun);
		},
		destroyed() {
			this.times && clearInterval(this.times);
		},
		mounted() {
			this.getPosType();

			this.$nextTick(() => {
				console.log("clientHeight", this.$refs.scrollView.clientHeight);
				this.resizeFun();
				// this.resizeFun窗口变化执行的方法
				window.addEventListener("resize", this.resizeFun);
			});

			// var rec = document.getElementById("zt");
			// var down = false;
			// var dx = 0;
			// var dy = 0;
			// var sx = 0;
			// var sy = 0;
			// document.onmousemove = function (e) {
			//   if (down) {
			//     console.log(44444);
			//     var ev = e || event;
			//     console.log(ev.clientY);
			//     rec.style.top = ev.clientY - (dy - sy) + "px";
			//     rec.style.left = ev.clientX - (dx - sx) + "px";
			//   }
			// };
			// rec.onmousedown = function () {
			//   console.log(5555);

			//   dx = event.clientX;
			//   dy = event.clientY;
			//   sx = parseInt(rec.style.left);
			//   sy = parseInt(rec.style.top);
			//   if (!down) {
			//     down = true;
			//   }
			// };
			// document.onmouseup = function () {
			//   if (down) {
			//     down = false;
			//   }
			// };
		},
		created: function() {
			document.getElementsByTagName("body")[0].style.zoom = 0.67 * 0.67;
			this.listDocuments();
			this.getBanner();
			this.getvipNotice();
			this.listDocumentsNoLogin();
			// this.showPopup2();
			document.addEventListener("keydown", (e) => {
				if (e.keyCode == 32 && this.drawer && !this.monitorFors) {
					this.changeBF();
				}
			});
			let setting = localStorage.getItem("setting");
			// let isYunsu = localStorage.getItem("isYunsu");
			// let isJinxiang = localStorage.getItem("isJinxiang");
			// let isBf = localStorage.getItem("isBf");
			// let bbf = localStorage.getItem("bbf");
			// let bbf2 = localStorage.getItem("bbf2");

			setTimeout(() => {
				// this.setting = JSON.parse(setting) || {
				//   speed: 3,
				//   speed2: 3,
				//   fontSize: 30,
				//   color: "#000",
				//   background: "#fff",
				//   letterSpacing: 100,
				// };
				// if (JSON.parse(isYunsu)) {
				//   this.isYunsu = JSON.parse(isYunsu);
				// } else {
				//   this.isYunsu = false;
				// }
				// if (JSON.parse(isJinxiang)) {
				//   this.isJinxiang = JSON.parse(isJinxiang);
				// } else {
				//   this.isJinxiang = false;
				// }
				// if (JSON.parse(isBf)) {
				//   this.isBf = JSON.parse(isBf);
				// } else {
				//   this.isBf = true;
				// }
				// if (JSON.parse(bbf)) {
				//   this.bbf = JSON.parse(bbf);
				// } else {
				//   this.bbf = false;
				// }
				// if (JSON.parse(bbf2)) {
				//   this.bbf2 = JSON.parse(bbf2);
				// } else {
				//   this.bbf2 = false;
				// }
			}, 1000);

			setInterval(() => {
				// this.loaclSetting();
				// console.log('setting',this.setting)
				// console.log('isYunsu',this.isYunsu)
				// console.log('isJinxiang',this.isJinxiang)
				// console.log('isBf',this.isBf)
				// console.log('bbf',this.bbf)
				// console.log('bbf2',this.bbf2)
			}, 2000);
		},
		methods: {
			getPosType() {
				console.log('触发')
				const agent = navigator.userAgent.toLowerCase();
				const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
				if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
					// console.error("这是windows32位系统");
				}
				if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
					// console.error("这是windows64位系统");
				}
				if (isMac) {
					this.isMac = true;
					// console.error("这是mac系统");
				} else {
					this.isMac = false;
				}
				if (
					!(
						/Safari/.test(navigator.userAgent) &&
						!/Chrome/.test(navigator.userAgent)
					)
				)
					this.isSafari = false;

				//判断是SAFARI时用这个

				if (
					/Safari/.test(navigator.userAgent) &&
					!/Chrome/.test(navigator.userAgent)
				)
					this.isSafari = true;

				this.isMacNoSafari = !this.isSafari && this.isMac;
			},
			resizeFun() {
				this.windowHeight = window.innerHeight;
			},
			setterDocument() {
				if (!this.$store.state.user) {
					// alert(222)
					return;
					// this.$message.error("请先登录");
				}
				this.$axios
					.post(
						"documents/setterDocument", {
							setting: this.setting,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						if (res.data.code == 200) {} else if (res.data.code == 500) {}
					});
			},
			getterDocument() {
				if (!this.$store.state.user) {
					// alert(222)
					return;
					// this.$message.error("请先登录");
				}
				this.$axios
					.post(
						"documents/getterDocument", {}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						if (res.data.code == 200) {
							console.log(res.data.data.setting);
							this.setting.background = res.data.data.setting.background;
							this.setting.color = res.data.data.setting.color;
							this.setting.fontSize = parseInt(res.data.data.setting.fontSize);
							this.setting.letterSpacing = parseInt(
								res.data.data.setting.letterSpacing
							);
							this.setting.speed = parseInt(res.data.data.setting.speed);
							this.setting.speed2 = parseInt(res.data.data.setting.speed2);
						} else if (res.data.code == 500) {}
					});
			},
			loaclSetting() {
				if (this.setting) {
					localStorage.setItem("setting", JSON.stringify(this.setting));
				} else {
					localStorage.setItem(
						"setting",
						JSON.stringify({
							speed: 13,
							speed2: 13,
							fontSize: 55,
							color: "#000",
							background: "#fff",
							letterSpacing: 100,
						})
					);
				}
				localStorage.setItem("isYunsu", this.isYunsu);
				localStorage.setItem("isJinxiang", this.isJinxiang);
				localStorage.setItem("isBf", this.isBf);
				localStorage.setItem("bbf", this.bbf);
				localStorage.setItem("bbf2", this.bbf2);
			},
			mousemoveE(e) {
				var rec = document.getElementById("eldrawer");

				// console.log('mousemoveE')
				//  console.log('down', this.down)
				if (this.down) {
					var ev = e || event;
					// console.log('ev.clientX', ev.clientX);
					// console.log('ev.clientY', ev.clientY);
					let dysy = this.dy - this.sy;
					let dxsx = this.dx - this.sx;
					let top = ev.clientY - dysy;
					let left = ev.clientX - dxsx;
					rec.style.top = top + "px";
					rec.style.left = left + "px";
					console.log("rec.style.top", ev.clientY - (this.dy - this.sy));
					console.log("rec.style.left", ev.clientX - (this.dx - this.sx));
				}
			},
			mousedownE(e) {
				if (!this.qqq) {
					return;
				}
				var rec = document.getElementById("eldrawer");
				console.log("eeee", e);

				console.log("mousedownE");
				this.dx = e.clientX;
				this.dy = e.clientY;
				this.sx = parseInt(rec.style.left || 0);
				this.sy = parseInt(rec.style.top || 0);
				// console.log("sx", rec.style.left || 0);
				// console.log("sy", rec.style.top || 0);
				if (!this.down) {
					this.down = true;
				}
			},
			mouseupE() {
				// console.log("mouseupE");
				if (this.down == true) {
					this.down = false;
				}
			},
			// cccc() {
			//   alert(4444);
			// },
			async changedrawer(id, item, bool) {
				this.chooseItem = item
				console.log(id);
				// if (!id) {
				//   this.contentArr = ['欢迎您使用提词王，你可以直接点击打开提词器红色按钮。下面我们开始吧！大江东去，浪淘尽，千古风流人物。故垒西边，人道是，三国周郎赤壁。乱石穿空，惊涛拍岸，卷起千堆雪。江山如画，一时多少豪杰。遥想公瑾当年，小乔初嫁了，雄姿英发。羽扇纶巾，谈笑间，樯橹灰飞烟灭。故国神游，多情应笑我，早生华发。人生如梦，一尊还酹江月。'];
				//   return;
				// }
				this.nowEditId = id;
				if (this.content && !bool) {
					/* if (!this.title) {
						this.title = "暂无标题";
					} */
					/* await this.addDocuments(); */
				}
				this.getApiData("/documents/docInfo", {
					id
				}).then(
					(r) => {
						this.contentArr = r.contentArr.map((item) => {
							return item ? item : "\n";
						});
						this.$forceUpdate();
					},
					(f) => {
						const docCount = window.localStorage.getItem("docCount");
						if (docCount) {
							// console.error(JSON.parse(docCount).forEach(item=>{
							//   console.error(item === '\n')
							// }));
							this.contentArr = JSON.parse(docCount).map((item) => {
								return item;
							});
						} else {
							this.contentArr = [
								"欢迎您使用提词王，你可以直接点击打开提词器，并支持在线编辑，分行展示。",
								"下面我们开始吧！",
								"大江东去，浪淘尽，千古风流人物。",
								"故垒西边，人道是，三国周郎赤壁。",
								"乱石穿空，惊涛拍岸，卷起千堆雪。",
								"江山如画，一时多少豪杰。",
								"遥想公瑾当年，小乔初嫁了，雄姿英发。",
								"羽扇纶巾，谈笑间，樯橹灰飞烟灭。",
								"故国神游，多情应笑我，早生华发。",
								"人生如梦，一尊还酹江月。",
							];
						}
						// this.getApiData("/setting/defaultDocument").then((r) => {

						//   if (r.content) this.contentArr = r.content;
						//   console.log(r, "rrrrrrr");
						// });
					}
				);
				this.mmmm = 0;
				this.drawer = true;
				// alert(444)
				if (!this.$store.state.user) {
					return;
					// this.$message.error("请先登录");
				}
				this.getterDocument();
			},
			changeqqq() {
				// if (this.disabled == true) {
				//   return;
				// }
				// this.mmmm = document.getElementById("drawer_conent").scrollTop;
				// alert(4444)
				if (this.qqq == 1) {
					this.qqq = false;
					this.drawerSize = "100%";
					this.drawerWidth = "100%";
					this.ztTop = "50%";
					this.ztTop2 = "120px";
					this.posWidth = "calc(100% - 40px)";
					this.ztTop3 = "0";
					this.heights = "10%";
					var rec = document.getElementById("eldrawer");

					rec.style.top = "0";
					rec.style.left = "0";
					this.drawerConent = "calc(100% - 100px)";

					// this.setting.fontSize = 26;
				} else {
					this.qqq = true;
					this.drawerSize = "70%";
					this.drawerWidth = "65%";
					// this.ztTop = "75%";
					// this.posWidth = "calc(60% - 40px)";
					this.settingRight = false;
					this.settingLeft = false;
					this.drawerConent = "calc(100% - 10px)";
					// this.ztTop2 = "calc(120px + 200px)";
					this.ztTop3 = "200px";
					this.heights = "10%";
					var rec = document.getElementById("eldrawer");
					// rec.style.top = "0";
					rec.style.top = "100px";
					rec.style.left = "500px";
					// this.setting.fontSize = 13;
				}
			},
			changeType(type) {
				this.popupType2 = type;
			},
			changeFree() {
				if (this.is_free == 0) {
					this.is_free = 1;
				} else {
					this.is_free = 0;
				}
			},
			showPopup2() {
				this.popup2 = true;
				this.popupType2 = "yzm";
			},
			closePopup2() {
				this.popup2 = false;
			},
			// 验证是否是手机号
			phoneTestTrim() {
				// /^1[3456789]\d{9}$/
				// /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/
				var reg = /^1[3456789]\d{9}$/;
				// console.log("phoneTest", reg.test(this.phone.trim()));
				this.phone = this.phone.replace(/[^\d]/g, "");
				this.phoneTest = reg.test(this.phone.trim());
			},
			// 手机密码登录
			phonePasswordLogin() {
				this.$axios
					.post("user/passLogin", {
						phone: this.phone,
						password: this.password,
						// //1为7天 0为1天
						is_free: this.is_free,
					})
					.then((res) => {
						// console.log(res.data.data.nickName.toString());
						if (res.data.code == 200) {
							localStorage.setItem("user", JSON.stringify(res.data.data));
							localStorage.setItem("username", res.data.data.nickName);
							// localStorage.setItem("username", '1234');
							this.closePopup();
							this.phone = "";
							this.password = "";
							this.is_free = 0;
							window.location.reload();
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 手机验证码登录
			phoneCodeLogin() {
				this.$axios
					.post("user/phoneLogin", {
						phone: this.phone,
						code: this.code,
						// //1为7天 0为1天
						is_free: this.is_free,
					})
					.then((res) => {
						// console.log(res.data.data.nickName.toString());
						if (res.data.code == 200) {
							localStorage.setItem("user", JSON.stringify(res.data.data.user));
							localStorage.setItem("username", res.data.data.user.nickName);
							// localStorage.setItem("username", '1234');

							this.closePopup();
							this.phone = "";
							this.password = "";
							this.code = "";
							this.is_free = 0;
							window.location.reload();
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 修改密码
			changePassword() {
				this.$axios
					.post("user/updatePass", {
						phone: this.phone,
						code: this.code,
						password: this.password,
						repassword: this.repassword,
					})
					.then((res) => {
						console.log(res.data);
						if (res.data.code == 200) {
							this.$message.success("修改成功");
							this.closePopup();
							this.phone = "";
							this.code = "";
							this.password = "";
							this.repassword = "";
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 获取验证码
			getCode() {
				if (!this.phoneTest) {
					return this.$message.error("请输入正确的手机号");
				}
				if (this.disabled == true) {
					return;
				}
				this.$axios
					.post("user/code", {
						phone: this.phone,
					})
					.then((res) => {
						console.log(res.data);
						this.disabled = true;
						this.countdown = 60;
						let timer = setInterval(() => {
							this.countdown--;
							if (this.countdown < 1) {
								clearInterval(timer);
								this.disabled = false;
								this.countdown = 0;
							}
						}, 1000);
						if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},

			handleExceed(files, fileList) {
				// this.$message.warning(
				//   `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
				//     files.length + fileList.length
				//   } 个文件`
				// );

				this.exceed = true;
				setTimeout(() => {
					this.exceed = false;
				}, 2000);
			},
			getvipNotice() {
				if (!this.$store.state.user) {
					// alert(222)
					// this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				console.log("33333333");
				this.$axios
					.post(
						"users/vipNotice", {}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);

						if (res.data.code == 200) {
							this.vipNotice = res.data.data;
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			getBanner() {
				console.log("33333333");
				this.$axios
					.post(
						"carousel/index",

						{}
						// { headers: { token: JSON.parse(this.$store.state.user).token } }
					)
					.then((res) => {
						console.log("222", res.data.data);

						if (res.data.code == 200) {
							this.bannerList = res.data.data;
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			reverseArr() {
				this.official_list.reverse();
				this.rese = !this.rese;
			},
			Time(value) {
				var date = new Date(parseInt(value) * 1000);
				var tt = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join("/") +
					"  " + [date.getHours(), date.getMinutes()].join(":");
				return tt;
			},

			keySign(title) {
				let keyWordArr = ["牛逼", "12", "还行"];
				var str = this.content2;
				console.log("str", str);
				// var str2 = this.content2;
				// this.chooseItem.content = ''
				// var str2 = this.chooseItem.id;
				// this.chooseItem.id = ''

				this.keyWordArr.forEach((e) => {
					console.log(e);
					let regStr = "" + `(${e})`;
					let reg = new RegExp(regStr, "g");
					// 如果匹配成功则抛出关键字DOM
					// TIPS: 这块您可以自定义标签可根据您的需求自定义样式
					str = str.replace(reg, '<span style="color: red;">' + e + "</span>"); // 改变搜索关键字颜色为红色
				});
				this.chooseItem.content2 = str;
				this.chooseItem.content = this.content2 + "+";
				// setTimeout(() => {
				// this.chooseItem.content = this.chooseItem.content + "-";

				this.chooseItem.content = this.chooseItem.content.substring(
					0,
					this.chooseItem.content.length - 1
				);

				// }, 100)

				// this.chooseItem.content= str2
				console.log("this.chooseItem.content ", this.chooseItem.content);
				console.log("this.chooseItem.content2 ", this.chooseItem.content2);

				return str;
			},
			// changeBFBtn(){
			//   this.monitorFors = true
			// },
			changeBF(e) {
				this.isBf = !this.isBf;
				this.mmmm = document.getElementById("drawer_conent").scrollTop;
				console.error(document.getElementById("drawer_conent").scrollTop);
				
				this.changeHuanyuan(!this.isBf);
				return;
			},
			mouseover() {
				this.mouse = true;
				// console.log("mouses", this.mouse);
			},
			mouseleave() {
				this.mouse = false;
				// this.down = false;
				console.log("mouses", this.mouse);
			},
			mouseleave2() {
				this.down = false;
			},
			changeHuanyuan(e) {
				console.error(e);
				// return
				// console.log("e", e);
				if (e == true) {
					// this.isYunsu = true;
					// this.changeSettingRight();
					this.settingRight = false;
					this.bbf = true;
					this.bbf2 = true;
					setTimeout(() => {
						// this.bbf = false;
						// this.bbf2 = false;
						// this.setting.speed2 = this.setting.speed;
						// this.changeBF("false");
						// this.isBf = false;
					}, 3000);
					this.countdown = 3;
					this.timer_233 = setInterval(() => {
						this.countdown--;
						if (this.countdown < 1) {
							clearInterval(this.timer_233);
							this.disabled = false;
							this.countdown = 0;
							this.bbf2 = false;
							this.isBf = false;
						}
					}, 1000);

					//  if (!this.$store.state.user) {
					// return;
					// this.$message.error("请先登录");
					// }
					this.setterDocument();
				} else {
					this.timer_233 && clearInterval(this.timer_233);
					this.bbf = false;
					// this.isYunsu = false;
				}
			},
			changeHuanyuan2(e) {
				if (e == true) {
					this.setting.speed = 20;
					// this.isYunsu = true;
					// // this.changeSettingRight();
					// this.settingRight = false;
					// this.bbf = true;
					// this.bbf2 = true;
					// // setTimeout(() => {
					// //   this.bbf2 = false;

					// //   this.setting.speed2 = this.setting.speed;
					// //   this.isBf = false
					// // }, 3000);

					// // this.countdown = 3;
					// // let timer = setInterval(() => {
					// //   this.countdown--;
					// //   if (this.countdown < 1) {
					// //     clearInterval(timer);
					// //     this.disabled = false;
					// //     this.countdown = 0;
					// //   }
					// // }, 1000);
				} else {
					this.bbf = false;
					this.isYunsu = false;
				}
			},
			changeJinxiang(e) {
				// if(e == true) {
				//   this.setting.speed = 1
				// }
			},
			changeFontColor(e) {
				this.setting.color = e;
			},
			changeBackground(e) {
				this.setting.background = e;
			},
			changeSettingLeft() {
				this.settingLeft = !this.settingLeft;
			},
			changeSettingRight() {
				this.setterDocument();
				console.log(this.settingRight);
				this.settingRight = !this.settingRight;
			},
			// 创建文案按钮
			createDocument() {


				this.chooseItem = "";
				this.isPen = true;
				this.content = "";
				this.title = "";
				//
				// console.log(this.official_list);

				// let off = this.official_list;
				// console.log("off", off);
				//   off.push({
				//     all_count: 0,
				//   });
				//   console.log("off2", off);
				// this.official_list = []
				// setTimeout(() => {
				// this.official_list.push({
				//   'title' : "新建 Microsoft Word 文档"
				// })
				// console.log('official_list', this.official_list)

				// }, 2000)
				let listDemo = [{
					content: "内容输入中",
					title: "内容输入中",
					choose: true,
				}, ];
				this.official_list.forEach((item, i) => {
					// if (i == index) {
					item.choose = false;
					// }
					listDemo.push(item);
				});
				this.official_list = listDemo;
				console.log(this.official_list);
			},
			changePen() {
				console.log(this.chooseItem);
				this.isPen = true;
				this.title = this.chooseItem.title;
				this.content = this.chooseItem.content;

				console.log(this.isPen);
			},
			// 文案或者回收站
			changeStatusSub(i) {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}

				this.status_sub = i;
				if (i == 2) {
					if (this.content) {
						console.log(222);

						/* if (!this.title) {
							this.title = "暂无标题";
						} */
						console.log(333);

						/* this.addDocuments(); */
					}
					this.isPen = false;
				}
				this.multi_select = false;
				this.official_list = [];
				this.tag_type = "";
				this.is_biaoq = false;
				this.page = 1;
				this.listDocuments();
			},
			onSearch() {
				if (!this.$store.state.user) {
					return;
					// this.$message.error("请先登录");
				}
				setTimeout(() => {
					// 无标签时
					if (this.tag_type == "") {
						this.$axios
							.post(
								"documents/lists", {
									// 每页条数
									listRows: 100,
									page: 1,
									// 搜索标题
									search: this.search,
									// 类型 1文档中删除 2回收站删除
									type: this.status_sub,
									// 标签类型 0无 1紧急 2等待 3完成 不传查询所有
									// tag_type: "",
								}, {
									headers: {
										token: JSON.parse(this.$store.state.user).token
									}
								}
							)
							.then((res) => {
								console.log(res.data.data.data);
								if (res.data.code == 200) {
									this.official_list = res.data.data.data;
									this.official_list.forEach((item, index) => {
										item.choose = false;
										item.is_top = false;
										item.is_bottom = false;
										item.is_xiala = false;
										item.is_biaoq = false;
									});
								} else if (res.data.code == -1) {
									this.$store.commit("exit");
									window.location.reload();
								} else {
									this.$message.error(res.data.msg);
								}
							});
					} else {
						// console.log(333)

						// 有tag时
						this.$axios
							.post(
								"documents/lists", {
									// 每页条数
									listRows: 100,
									page: 1,
									// 搜索标题
									search: this.search,
									// 类型 1文档中删除 2回收站删除
									type: this.status_sub,
									// 标签类型 0无 1紧急 2等待 3完成 不传查询所有
									tag_type: this.tag_type,
								}, {
									headers: {
										token: JSON.parse(this.$store.state.user).token
									}
								}
							)
							.then((res) => {
								console.log(res.data.data.data);
								if (res.data.code == 200) {
									this.official_list = res.data.data.data;
									this.official_list.forEach((item, index) => {
										item.choose = true;
										item.is_top = false;
										item.is_bottom = false;
										item.is_xiala = false;
										item.is_biaoq = false;
									});
								} else if (res.data.code == -1) {
									this.$store.commit("exit");
									window.location.reload();
								} else {
									this.$message.error(res.data.msg);
								}
							});
					}
				}, 300);
			},
			// 刷新
			refr() {
				this.official_list = [];
				this.tag_type = "";
				this.is_biaoq = false;
				this.page = 1;
				this.listDocuments();
			},
			changeIsBiaoq() {
				this.is_biaoq = !this.is_biaoq;
				// this.official_list = [];
				this.page = 1;
				this.listDocuments();
			},
			changeTag(i) {
				// this.official_list = [];
				this.tag_type = i;
				this.is_biaoq = false;
				this.page = 1;
				this.listDocuments();
			},
			navigateMember() {
				this.$router.push("member");
			},
			// 上传文件
			submitUpload() {
				// this.loading = true
				console.log(this.loading);
				this.$refs.upload.submit();
				console.log(333);

				this.popup = false;
				
			},
			handleAvatarSuccess(res) {
				console.log(res);

				this.loading = false;

				if (res.code == 200) {
					this.$message.success("上传成功");
					setTimeout(() => {
						this.page = 1;
						this.listDocuments();
					}, 400);
				} else {
					this.$message.error(res.msg);
				}
			},
			// 滚动到顶部
			handleScrollTop() {
				this.$nextTick(() => {
					let scrollElem = this.$refs.scrollDiv; // 对应ref的命名
					scrollElem.scrollTo({
						top: 0,
						behavior: "smooth"
					});
				});
			},
			// 滚动到底部
			handleScrollBottom() {
				this.$nextTick(() => {
					let scrollElem = this.$refs.scrollDiv; // 对应ref的命名
					scrollElem.scrollTo({
						top: scrollElem.scrollHeight,
						behavior: "smooth",
					});
				});
			},
			// 已经滚动到底部
			handleScroll(e) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight
				} = e.target;
				// console.log(scrollTop, clientHeight, scrollHeight)
				// console.log ('a', scrollTop + clientHeight)
				// console.log(scrollHeight)
				// console.log(150 -( (scrollTop + clientHeight)/scrollHeight * 100))
				// this.sliderValue = 150 -( (scrollTop + clientHeight)/scrollHeight * 100)
				if (scrollTop + clientHeight + 50 > scrollHeight) {
					// alert('滚动到底部啦')

					if (this.isLoading) return;
					if (this.multi_select) return;
					console.log(this.isLoading);
					console.log(this.multi_select);
					this.page++;
					this.listDocuments();
				}
			},
			// 文档列表
			listDocuments() {
				if (!this.$store.state.user) {
					return;
					// this.$message.error("请先登录");
				}

				//开启节流阀
				this.isLoading = true;

				// 无标签时
				if (this.tag_type == "") {
					this.$axios
						.post(
							"documents/lists", {
								// 每页条数
								listRows: 15,
								page: this.page,
								// 搜索标题
								search: this.search,
								// 类型 1文档中删除 2回收站删除
								type: this.status_sub,
								// 标签类型 0无 1紧急 2等待 3完成 不传查询所有
								// tag_type: "",
							}, {
								headers: {
									token: JSON.parse(this.$store.state.user).token
								}
							}
						)
						.then((res) => {
							//请求完成，关闭节流阀
							setTimeout(() => {
								this.isLoading = false;
							}, 500);
							console.log(res.data.data.data);
							if (res.data.code == 200) {
								if (res.data.data.data == "") {
									return;
								}
								if (this.page == 1) {
									this.official_list = res.data.data.data;
								} else {
									this.official_list = this.official_list.concat(
										res.data.data.data
									);
								}

								this.official_list.forEach((item, index) => {
									item.choose = false;
									item.is_top = false;
									item.is_bottom = false;
									item.is_xiala = false;
									item.is_biaoq = false;
									if (item.id === this.chooseItem.id) {
										this.$set(this, "chooseItem", item);
										this.$set(this, "content", item.content);
									}
								});
							} else if (res.data.code == -1) {
								this.$store.commit("exit");
								window.location.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						});
				} else {
					// console.log(333)

					// 有tag时
					this.$axios
						.post(
							"documents/lists", {
								// 每页条数
								listRows: 15,
								page: this.page,
								// 搜索标题
								search: this.search,
								// 类型 1文档中删除 2回收站删除
								type: this.status_sub,
								// 标签类型 0无 1紧急 2等待 3完成 不传查询所有
								tag_type: this.tag_type,
							}, {
								headers: {
									token: JSON.parse(this.$store.state.user).token
								}
							}
						)
						.then((res) => {
							//请求完成，关闭节流阀
							setTimeout(() => {
								this.isLoading = false;
							}, 500);
							console.log(res.data.data.data);
							if (res.data.code == 200) {
								if (res.data.data.data == "") {
									return;
								}
								// this.official_list = this.official_list.concat(
								//   res.data.data.data
								// );
								// this.official_list.forEach((item, index) => {
								//   item.choose = true;
								//   item.is_top = false;
								//   item.is_bottom = false;
								//   item.is_xiala = false;
								//   item.is_biaoq = false;
								// });
								if (this.page == 1) {
									this.official_list = res.data.data.data;
								} else {
									this.official_list = this.official_list.concat(
										res.data.data.data
									);
								}

								this.official_list.forEach((item, index) => {
									item.choose = false;
									item.is_top = false;
									item.is_bottom = false;
									item.is_xiala = false;
									item.is_biaoq = false;
								});
							} else if (res.data.code == -1) {
								this.$store.commit("exit");
								window.location.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						});
				}
			},
			// 文档列表默认文档
			listDocumentsNoLogin() {
				// 无标签时
				if (this.tag_type == "" && !JSON.parse(this.$store.state.user)) {
					this.$axios.post("setting/defaultDocument", {}).then((res) => {
						
						if (res.data.code == 200) {
							let list=[]
							list.push(res.data.data)
							this.official_list=list;
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}
			},
			// 还原单选
			huanyuan() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				console.log(this.chooseItem);
				if (!this.chooseItem) {
					return this.$message.error("请选择要还原的文档");
				}
				// console.log(2222);
				let ids = [];
				ids.push(this.chooseItem.id);
				this.$axios
					.post(
						"documents/reduction", {
							ids,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						// let www = [];
						// www = res.data.data;
						if (res.data.code == 200) {
							this.page = 1;
							this.official_list = [];
							this.listDocuments();
							this.chooseItem = "";
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 还原多选
			huanyuanD() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				this.official_list.forEach((item, index) => {
					if (item.choose) {
						ids.push(item.id);
					}
				});
				this.$axios
					.post(
						"documents/reduction", {
							ids,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						// let www = [];
						// www = res.data.data;
						if (res.data.code == 200) {
							this.page = 1;
							this.official_list = [];
							this.listDocuments();
							this.chooseItem = "";
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 文案删除文档类型单选
			deleteDocument(item) {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				ids.push(item.id);
				this.$axios
					.post(
						"documents/delete", {
							ids,
							type: this.status_sub,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						if (res.data.code == 200) {
							this.page = 1;
							this.official_list = [];
							this.listDocuments();

							this.chooseItem = "";

							this.documentSC = true;
							this.documentSCTitle = "已删除";
							setTimeout(() => {
								this.documentSC = false;
							}, 1000);
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 回收站删除文档类型单选
			deleteDocumentHS() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				ids.push(this.chooseItem.id);
				this.$axios
					.post(
						"documents/delete", {
							ids,
							type: this.status_sub,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						if (res.data.code == 200) {
							this.page = 1;
							this.official_list = [];
							this.listDocuments();
							this.chooseItem = "";
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 删除文档类型多选
			deleteDocumentD() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				this.official_list.forEach((item, index) => {
					if (item.choose) {
						ids.push(item.id);
					}
				});
				this.$axios
					.post(
						"documents/delete", {
							ids,
							type: this.status_sub,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						if (res.data.code == 200) {
							this.page = 1;
							this.official_list = [];
							this.listDocuments();
							this.chooseItem = "";
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 文档置顶/置底
			tabDocument(item, type) {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				this.$axios
					.post(
						"documents/tab", {
							id: item.id,
							// //类型 1置顶 2置底
							type,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log(res.data.data);
						if (res.data.code == 200) {
							this.$message.success("修改成功");
							this.page = 1;
							this.tag_type = "";
							this.listDocuments();

							if (type == 1) {
								this.documentSC = true;
								this.documentSCTitle = "已置顶";
								setTimeout(() => {
									this.documentSC = false;
								}, 1000);
							} else if (type == 2) {
								this.documentSC = true;
								this.documentSCTitle = "已置底";
								setTimeout(() => {
									this.documentSC = false;
								}, 1000);
							}
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 下载文档自选
			downDocumentWD() {
				if (!this.$store.state.user) {
					// alert(222);
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				ids.push(this.chooseItem.id);
				this.$axios
					.post(
						"documents/down", {
							ids,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						if (res.data.code == 200) {
							www.forEach((down, index) => {
								// window.open(down);
								document.getElementById("ahref").href = down;
								document.getElementById("ahref").click();
							});
							console.log("www", www);
							this.$message.success("下载成功");
							// this.page = 1;
							// this.official_list = [];
							// this.listDocuments();
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 下载文档类型单选
			downDocument(item) {
				// alert(444)
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				ids.push(item.id);
				this.$axios
					.post(
						"documents/down", {
							ids,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						if (res.data.code == 200) {
							www.forEach((down, index) => {
								// alert(444)
								// document.getElementById("ahref").href = down;
								//   document.getElementById("ahref").click();
								// window.open(down);
								(function() {
									setTimeout(() => {
										document.getElementById("ahref").href = down;
										document.getElementById("ahref").click();
										// window.open(down);
									}, index * 500);
								})();
							});
							console.log("www", www);
							this.$message.success("下载成功");
							// this.page = 1;
							// this.official_list = [];
							// this.listDocuments();
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 下载文档类型多选
			downDocumentD() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				let ids = [];
				this.official_list.forEach((item, index) => {
					if (item.choose) {
						ids.push(item.id);
					}
				});
				if (ids.length < 1) {
					return this.$message.error("请选择想要下载的文案");
				}
				this.$axios
					.post(
						"documents/down", {
							ids,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log("222", res.data.data);
						let www = [];
						www = res.data.data;
						//  let triggerDelay = 100;
						//     let removeDelay = 1000;
						if (res.data.code == 200) {
							www.forEach((down, ind) => {
								(function() {
									setTimeout(() => {
										document.getElementById("ahref").href = down;
										document.getElementById("ahref").click();
										// window.open(down);
									}, ind * 500);
								})();
							});
							console.log("www", www);
							this.$message.success("下载成功");
							// this.page = 1;
							// this.official_list = [];
							// this.listDocuments();
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 修改文档类型
			changeDocumentTag(item, tag_type) {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				this.$axios
					.post(
						"documents/tag", {
							id: item.id,
							tag_type,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log(res.data.data);
						if (res.data.code == 200) {
							this.$message.success("修改成功");
							this.page = 1;
							// this.official_list = [];
							this.listDocuments();
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 添加/修改文档
			addDocuments() {
				if (!this.content) return;
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				
				if(!this.title){
					this.$message.error("请先填写标题");
					return
				}
				
				// 添加文档
				if (this.isPen && this.chooseItem == "") {
					return this.$axios
						.post(
							"documents/add", {
								title: this.title,
								content: this.content
									.replace(/\r\n/g, "\n")
									.replace(/\n/g, "\r\n"),
							}, {
								headers: {
									token: JSON.parse(this.$store.state.user).token
								}
							}
						)
						.then((res) => {
							console.log(res.data.data);
							if (res.data.code == 200) {
								this.$message.success("保存成功");
								this.title = "";
								this.content = "";
								this.page = 1;
								// this.official_list = [];
								this.listDocuments();
							} else if (res.data.code == 500) {
								return this.$message.error(res.data.msg);
							} else if (res.data.code == -1) {
								this.$store.commit("exit");
								window.location.reload();
							} else if (res.data.code == 502) {
								this.popupType = "documentAdd";
								this.popup = true;
							} else if (res.data.code == 503) {
								this.$message.error(res.data.msg);
							} else {
								this.$message.error(res.data.msg);
							}
						});
				}
				// 修改文档
				else if (this.isPen && this.chooseItem) {
					return this.$axios
						.post(
							"documents/update", {
								title: this.title,
								content: this.content
									.replace(/\r\n/g, "\n")
									.replace(/\n/g, "\r\n"),
								id: this.chooseItem.id,
							}, {
								headers: {
									token: JSON.parse(this.$store.state.user).token
								}
							}
						)
						.then((res) => {
							if (res.data.code == 200) {
								this.$message.success("修改成功");
								// this.title = "";
								// this.content = "";
								this.page = 1;
								// this.official_list = [];
								this.listDocuments();
							} else if (res.data.code == 500) {
								return this.$message.error(res.data.msg);
							} else if (res.data.code == -1) {
								this.$store.commit("exit");
								window.location.reload();
							} else if (res.data.code == 502) {
								this.popupType = "documentAdd";
								this.popup = true;
							} else {
								this.$message.error(res.data.msg);
							}
						});
				}
			},
			// 文档检测
			detectDocuments() {
				if (!this.$store.state.user) {
					return;
					// this.$message.error("请先登录");
				}
				let content;
				// this.chooseItem.content = this.content
				if (this.isPen) {
					content = this.content;
					this.content2 = this.content;

					console.log(3333, this.content);
				} else {
					content = this.chooseItem.content;
					this.content2 = this.chooseItem.content;

					console.log(4444444, this.chooseItem.content);
				}
				// return

				/* this.addDocuments(); */
				console.log("this.chooseItem.content", this.chooseItem.content);
				this.isPen = false;

				this.$axios
					.post(
						"documents/detect", {
							content,
						}, {
							headers: {
								token: JSON.parse(this.$store.state.user).token
							}
						}
					)
					.then((res) => {
						console.log(res.data.data);
						if (res.data.code == 200) {
							// this.$message.success("无违规词");
							this.keyWordArr = res.data.data;
							if (this.keyWordArr == []) {
								this.$message.success("无违规词");
							}
							// return
							this.keySign();
						} else if (res.data.code == -1) {
							this.$store.commit("exit");
							window.location.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 开启多选
			duoxuan() {


				this.multi_select = !this.multi_select;
				this.official_list.forEach((item) => {
					item.choose = false;
				});
			},
			//判断是否全选
			is_all_choice() {
				this.is_choice = true;
				this.official_list.forEach((item) => {
					if (!item.choose) {
						this.is_choice = false;
						throw new Error("");
					}
				});
			},
			//全选
			choice_All() {
				if (this.is_choice) {
					this.official_list.forEach((item) => {
						item.choose = false;
					});
					this.is_choice = false;
				} else {
					this.official_list.forEach((item) => {
						item.choose = true;
					});
					this.is_choice = true;
				}
				// this.official_list.forEach((item) => {
				//   item.choose = !this.is_choice;
				// });
				// this.is_all_choice();
			},
			// 下拉1
			changeXiala(index) {
				let listDemo = [];
				this.official_list.forEach((item, i) => {
					if (i == index) {
						item.is_xiala = !item.is_xiala;
					}
					listDemo.push(item);
				});
				this.official_list = listDemo;
			},
			// 下拉2
			changeBiaoq(index) {
				let listDemo = [];

				this.official_list.forEach((item, i) => {
					if (i == index) {
						item.is_biaoq = !item.is_biaoq;
					}
					listDemo.push(item);
				});
				this.official_list = listDemo;
			},
			async choEscice(item, index) {
				if (
					this.chooseItem?.id === item.id
				) return;
				await this.closeDrawer()
				console.error(item);
				this.changedrawer(item.id, item, true)
			},
			//选择
			async choice(item, index) {

				if (
					this.chooseItem?.id === item.id &&
					this.chooseItem.content === this.content
				) {
					return;
				} else if (this.chooseItem.id === item.id) {
					/* alert('触发了这里',this.content)
					return
					this.official_list[index].content = this.content; */
				}
				
				
				
				if (this.content) {
					/* if (!this.title) {
						this.title = "暂无标题";
					} */
					console.log(333);
				}
				/* await this.addDocuments(); */

				this.isPen = false;
				// document.getElementById("drawer_conent").scrollTop = 0;
				this.heights = "10%";
				if (!this.multi_select) {
					console.log(this.multi_select);
					this.chooseItem = item;
					this.nowEditId = item.id;
					this.isPen = false;
					this.contentArr = item.content.split("\r\n");
					// console.log(this.chooseItem);
					let listDemo1 = [];
					this.official_list.forEach((item, i) => {
						item.choose = false;
						listDemo1.push(item);
					});
					this.official_list = listDemo1;
				}

				//
				// setTimeout(() => {s
				let listDemo = [];
				this.official_list.forEach((item, i) => {
					if (i == index) {
						item.choose = !item.choose;
					}
					listDemo.push(item);
				});
				this.official_list = listDemo;
				// }, 50);

				// let sub = this.official_list.findIndex((item) => {
				//   return item.id == id;
				// });
				// console.log("222", sub);

				// this.official_list[sub].choose = !this.official_list[sub].choose;
				// console.log(this.official_list);
			},
			showPopup() {
				if (!this.$store.state.user) {
					// alert(222)
					this.showPopup2();
					return;
					// this.$message.error("请先登录");
				}
				this.popupType = "upload";
				this.popup = true;
				this.$refs["upload"].clearFiles();
			},
			closePopup() {
				if (this.times) {
					clearInterval(this.times);
				}
				this.popup = false;
			},
			openDrawer() {
				// alert("22");
			},
			beforeAvatarUpload(file) {
				console.log("file.type", file.type);
				const isDoc111 =
					file.type ===
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
				const isDoc222 = file.type === "application/msword";
				const isDoc333 = file.type === "text/plain";

				console.log("isDoc111", isDoc111);
				console.log("isDoc222", isDoc222);
				console.log("isDoc333", isDoc333);

				if (!isDoc111 & !isDoc222 & !isDoc333) {
					// alert('222222')
					this.$message.error("上传的文件只能是doc、docx、txt格式!");
				}

				// return isDoc;
			},
			getPostHtml() {
				this.changeBF();
			},
			async closeDrawer(bool) {
				const drawer_post = this.$refs.drawer_post.childNodes;

				!bool && this.$refs.navDrawer.closeDrawer();
				let list = [...drawer_post].map((item, index) => {
					const {
						innerText
					} = item;
					// this.$refs.drawer_post.removeChild(drawer_post[index])
					return innerText;
				});
				await this.getApiData("/documents/docSave", {
						id: this.nowEditId,
						doc_arr: list
							.filter((item) => !!item)
							.map((item) => {
								return item === "\n" ? "" : item;
							}),
					})
					.then((r) => {
						console.log(r);
						this.listDocuments();
					})
					.catch((f) => {
						window.localStorage.setItem("docCount", JSON.stringify(list));
					});
				// this.$refs.drawer_post.childNodes = []
				// list
			},

			getApiData(url, params) {
				return new Promise((resolve, reject) => {
					this.$axios
						.post(url, params, {
							headers: {
								token: JSON.parse(this.$store.state.user)?.token
							},
						})
						.then((res) => {
							if (res.data.code == 200) {
								resolve(res.data.data);
							} else if (res.data.code == 500) {
								this.$message.error(res.data.msg);
								resolve({
									contentArr: [""]
								});
							} else {
								reject(res.data);
							}
						});
				});
			},
		},
	};
</script>

<style lang="scss">
	// .margin0 {
	//   margin-top: 0px !important;
	//   background: red !important;
	// }

	.opacity0 {
		// transition: all 1s;
		// opacity: 0.6;
	}

	.opacity1 {
		// transition: all 1s;

		opacity: 1;
	}

	.home {}

	.re {
		transform: rotate(180deg);
	}

	.el-carousel--horizontal {
		overflow: hidden !important;
	}

	.documentSC-popup {
		position: absolute;
		width: 130px;
		height: 130px;
		background: rgba(31, 36, 34, 0.65);
		top: 30%;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 16px;
		z-index: 100000;
	}

	.documentSC-popup .sc-img {
		display: block;
		width: 48px;
		height: 48px;
		margin: 26px auto 9px;
	}

	.documentSC-popup .sc-text {
		font-size: 18px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
	}

	.moreChoose {
		width: 705px;
		height: 78px;
		background: #f2eded;
		box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 8px 8px 0px 0px;
		opacity: 1;
		padding: 0 71px 0 73px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		margin-left: -53px;
	}

	.moreChoose .ch1 {
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #fe4365;
	}

	.moreChoose .ch2 {
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.moreChoose .ch3 {
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.upload-popup {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		width: 488px;
		/* height: 505px; */
		background: #ffffff;
		opacity: 1;
		z-index: 1000;
		padding: 32px 26px 21px;
		border-radius: 10px;
	}

	.upload-pp {
		position: absolute !important;
	}

	.upload-popup .upload-popup-exit {
		position: absolute;
		top: 24px;
		left: 24px;
		width: 22px;
		height: 22px;
	}

	.upload-popup .upload-popup-h1 {
		font-size: 20px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #000000;
		text-align: center;
	}

	.upload-popup-h2 {
		width: 435px;
		min-height: 267px;
		background: #f7f9fa;
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		border: 2px dashed #e6e8e9;
		margin: 31px auto 0px;
		/* padding: 55px 189px 53px 111px; */
	}

	.upload-popup-h2 .h2-1 {
		width: 58px;
		height: 45px;
		margin-top: 55px;
		margin-bottom: 29px;
	}

	.upload-popup-h2 .h2-2 {
		font-size: 14px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #333333;
		text-align: center;
	}

	.upload-popup-h2 .h2-3 {
		font-size: 13px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #fe4365;
		width: 213px;
		text-align: center;
		margin: 11px auto 53px;
	}

	.upload-popup-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 83px;
	}

	.upload-popup-btn .btn1 {
		width: 61px;
		height: 40px;
		background: #edf2f5;
		border-radius: 8px 8px 8px 8px;
		opacity: 1;
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 24px;
	}

	.upload-popup-btn .btn2 {
		width: 61px;
		height: 40px;
		background: #fe4365;
		border-radius: 8px 8px 8px 8px;
		// opacity: 0.3;
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.documentAdd-popup {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		width: 488px;
		/* height: 505px; */
		background: #ffffff;
		opacity: 1;
		z-index: 1000;
		padding: 57px 20px 26px 38px;
		border-radius: 10px;
	}

	.documentAdd-popup>.documentAdd-popup-exit {
		position: absolute;
		top: 24px;
		left: 24px;
		width: 22px;
		height: 22px;
	}

	.documentAdd-popup>.documentAdd-popup-h1 {
		font-size: 20px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #000000;
		text-align: center;
	}

	.documentAdd-popup>.documentAdd-popup-h2 {
		font-size: 29px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #e82828;
		text-align: center;
	}

	.documentAdd-popup>.documentAdd-popup-h3 {
		font-size: 20px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		text-align: center;
		margin-top: 29px;
		margin-bottom: 80px;
		line-height: 36px;
	}

	.documentAdd-popup>.documentAdd-popup-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.documentAdd-popup>.documentAdd-popup-btn .btn1 {
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #686c6a;
		margin-right: 64px;
	}

	.documentAdd-popup>.documentAdd-popup-btn .btn2 {
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #2dbe5f;
	}

	.drawer {
		font-size: 36px;
		/* color: red; */
		/* height: 1600px; */
		position: relative;
		/* background: red; */
		width: 100%;
		// height: calc(100% - 10px);
		background: rgb(237, 237, 237);
		/* background: red; */
		padding: 20px;
	}

	.drawer_top {
		background: #000;
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 72px;
	}

	.t-left {
		color: #fff;
		font-size: 36px;
	}

	.eldrawer {
		// background: red;
		width: 100%;
		height: 100%;
		position: relative;
		margin: 0 auto;
		overflow: hidden;
	}

	.eldrawer .drawer_pos {
		position: absolute;
		background: rgba(194, 191, 191, 0.7);
		top: 120px;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100% - 40px);
		height: 100px;
		border-bottom: 4px solid rgb(254, 67, 101);
		transition: height 0.01s;
		border-radius: 10px 10px 0 0;
		z-index: 9999 !important;
	}

	.eldrawer .drawer_post {
		/* position: absolute; */
		/* top: 120px;
  background: red; */
		width: 100%;
		/* left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  height: 100px;
  border-bottom: 4px solid rgb(254, 67, 101);
  transition: height 0.01s;
  border-radius: 10px 10px 0 0; */
		// background: red;
		padding-top: 10%;

		p {
			border-bottom: 1px solid #444;
		}

		p:last-child {
			border-bottom: 4px solid #4297d7;
		}
	}

	.drawer_conent {
		position: relative;
		height: calc(100% - 100px);
		overflow-y: auto;
		background: #fff;
		border-radius: 10px 10px 20px 20px;
		padding: 20px;
		// text-align: center;
	}

	.drawer_conent::-webkit-scrollbar {
		display: none;
	}

	.zt {
		position: absolute;
		width: 148px;
		height: 148px;
		/* top: 50%; */
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		z-index: 999;
	}

	.drawer_conent.jinxiang {
		transform: rotateY(180deg);
	}

	.drawer_bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: rgb(41, 50, 60);
		display: flex;
		align-items: center;
		z-index: 10000;
	}

	.drawer_bottom .btn1 {
		flex: 1;
		font-size: 26px;

		color: #fff;
		text-align: center;
	}

	.drawer_bottom .btn2 {
		flex: 1;
		font-size: 26px;
		color: #fff;
		text-align: center;
	}

	.draw-bottom-right {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: 622px;
		background: rgba(116, 92, 92, 0.65);
		border-radius: 8px 8px 0px 0px;
		z-index: 10001;
		padding: 34px 222px 62px 215px;
	}

	.rightcontent {
		// width: calc(1920 * 0.7px);
		margin: 0 auto;
	}

	.tc-h2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 36px;
		font-family: PingFang SC-Light, PingFang SC;
		font-weight: 300;
		color: #ffffff;
		width: 965px;
		margin: 0 auto;
	}

	.tc-h3 {
		width: 1000px;
		margin: 51px auto 0;
		height: 400px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.tc-h3 .tc-h3-list {
		width: 965px;
		height: 70px;
		border-radius: 12px 12px 12px 12px;
		opacity: 1;
		font-size: 25px;
		background: #ffffff;

		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 21px 0 26px;
		margin-bottom: 12px;
		margin-right: 20px;
	}

	/*滚动条整体部分,必须要设置*/
	.tc-h3::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: transparent;
	}

	/*滚动条的轨道*/
	.tc-h3::-webkit-scrollbar-track {
		background-color: transparent;
	}

	/*滚动条的滑块按钮*/
	.tc-h3::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(254, 67, 101, 1);
		box-shadow: inset 0 0 5px rgba(254, 67, 101, 1);
	}

	/*滚动条的上下两端的按钮*/
	.tc-h3::-webkit-scrollbar-button {
		height: 0;
		background-color: rgba(254, 67, 101, 1);
	}

	/* // 两个滚动条的交汇处 */
	.tc-h3::-webkit-scrollbar-corner {
		background-color: transparent;
	}

	.tc-h3 .tc-h3-list .l1 {
		flex: 1;
	}

	.tc-h3 .tc-h3-list .l2 {
		flex: 2;
		text-align: center;
	}

	.tc-h3 .tc-h3-list .l3 {
		flex: 1;
		text-align: right;
	}

	.tc-h3 .tc-h3-list.active {
		width: 965px;
		height: 68px;
		background: #fe4365;
		color: #ffffff;
		border-radius: 12px 12px 12px 12px;
		opacity: 1;
		padding: 16px 21px 16px 26px;
	}

	.draw-bottom-right .dr-h1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 44px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		// background: red;
		margin-bottom: 34px;
	}

	.draw-bottom-right .dr-h2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 45px;
	}

	.draw-bottom-right .dr-h2 .dr-h2-left {
		display: flex;
		align-items: center;
		/* background: red; */
		// flex: 1;
		// margin-right: 270px;
		// background: red;
		width: 454px;
	}

	.draw-bottom-right .dr-h2 .dr-h2-left .switch-title {
		font-size: 36px;
		font-family: PingFang SC-Light, PingFang SC;
		font-weight: 300;
		color: #ffffff;
		/* margin-right: 227px; */
		/* margin-right: 50%; */
		flex: 1;
		/* background: red; */
	}

	.draw-bottom-right .dr-h2 .dr-h2-right {
		display: flex;
		align-items: center;
	}

	.draw-bottom-right .dr-h2 .dr-h2-right .color-title {
		font-size: 36px;
		font-family: PingFang SC-Light, PingFang SC;
		font-weight: 300;
		color: #ffffff;
		/* margin-left: 270px; */
		margin-right: 48px;
	}

	.draw-bottom-right .dr-h2 .dr-h2-right .color-choose {
		width: 46px;
		height: 46px;
		background: #29333c;
		border-radius: 4px 4px 4px 4px;
		opacity: 1;
		margin-right: 19px;
	}

	.draw-bottom-right .dr-h3 {
		display: flex;
		align-items: center;
		/* justify-content: space-between; */
		margin-bottom: 45px;
	}

	.draw-bottom-right .dr-h3 .h3-title {
		font-size: 36px;
		font-family: PingFang SC-Light, PingFang SC;
		font-weight: 300;
		color: #ffffff;
		/* background: red; */
	}

	.draw-bottom-right .dr-h3 .h3-slider {
		/* background: red; */
		width: 208px;
		margin-left: 175px;
	}

	.h3-slider {}

	.h3-slider .el-slider__runway {
		background: rgba(255, 255, 255, 1) !important;
		height: 3px;
	}

	.h3-slider .el-slider__bar {
		background: rgba(255, 255, 255, 1) !important;
		height: 3px;
	}

	.h3-slider .el-slider__button {
		border: 2px solid rgba(255, 255, 255, 1) !important;
		background: rgba(254, 67, 101, 1) !important;
		width: 30px;
		height: 30px;
		margin-top: -2px;
	}

	.draw-bottom-right .dr-h3 .h3-tip {
		/* background: red; */
		/* width: 208px; */
		margin-left: 21px;
		font-size: 32px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.draw-bottom-right .el-switch__core {
		height: 36px !important;
		border-radius: 115px;
		width: 93px !important;
	}

	.draw-bottom-right .el-switch__core::after {
		height: 34px !important;
		width: 34px !important;
		/* margin-left: -16px !important; */
		top: 50%;
		transform: translateY(-50%);
	}

	.el-drawer {
		background: rgba(0, 0, 0, 0) !important;
	}

	.el-drawer__body {
		/* margin: 0px 10px 10px 10px; */
		/* background: rgba(19, 32, 42, 0.5) !important; */
		padding: 0;
		width: 100% !important;
		/* height: 340px; */
		position: relative;
		/* background: red; */
		background: rgba(0, 0, 0, 0) !important;
	}

	.banner {
		width: 1872px;
		height: 240px;
		/* background: #000; */
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin: -110px auto 0;
	}

	.banner img {
		width: 100%;
		height: 100%;
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		/* margin: -110px auto 0; */
	}

	.content {
		display: flex;
		padding: 50px 0 200px 0;
		width: 1872px;
		height: 1325px;
		background: #fafafa;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 30px 30px 0px 0px;
		opacity: 1;
		margin: 0 auto 0;
		margin-top: 24px;
		position: relative;
	}
	.content-qrCode{
		position: absolute;
		width: 418px;
		height: 556px;
		top:5%;
		left: -35%;
		transform: translate(35%,-5%);
	}
	.content-left {}

	.content-left-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 189px;
		height: 88px;
		background: rgba(250, 250, 250, 0);
		border-radius: 0px 10px 10px 0px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #707070;
		cursor: pointer;
	}

	.content-left-btn.active {
		width: 189px;
		height: 88px;
		background: #fe4365;
		border-radius: 0px 10px 10px 0px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.content-left-btn.active1 {
		width: 189px;
		height: 88px;
		background: #2dc05f;
		border-radius: 0px 10px 10px 0px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.content-hr {
		width: 1px;
		height: 1078px;
		opacity: 0.5;
		border-left: 1px solid #707070;
	}

	.content-center {
		position: relative;
		max-width: 786px;
	}

	.content-right {
		margin-right: 20px;
	}

	.content-center-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content-center-header-left {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 183px;
		height: 61px;
		background: #fe4365;
		border-radius: 8px 8px 8px 8px;
		opacity: 1;
		font-size: 24px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.content-center-header-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 30px;
		width: 308px;
		height: 60px;
		background: rgba(254, 67, 101, 0.2);
		border-radius: 30px 30px 30px 30px;
		opacity: 1;
	}

	.content-center-header-right>.r1 {
		font-size: 24px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #fe4365;
	}

	.content-center-header-right>.r2 {
		width: 2px;
		height: 25px;
		background: #fe4365;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}

	.content-center-t2 {
		font-size: 32px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #333333;
		margin-top: 36px;
		/* font-family: 'PB' !important; */
	}

	.content-center-t3 {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-top: 36px;
		margin-bottom: 14px;
	}

	.content-center-t3>.t3-left {
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.puit_all {
		display: flex;
		align-items: center;
	}

	.puit_all>span {
		margin-left: 8px;
	}

	.content-center-t3>.t3-right {
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.content-center-t3>.t3-right.acs {
		color: #1a72cb;
	}

	.content-center-t4 {
		display: flex;
		align-items: center;
		position: relative;
	}

	.content-center-t4 .el-input__inner {
		resize: none !important;
		border: none !important;
		width: 396px;
		height: 69px;
		background: #f6f6f6;
		border-radius: 35px 35px 35px 35px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		/* color: #999999; */
		margin-right: 50px;
		padding: 0 40px;
		text-align: center;
	}

	.content-center-t4>.t4-1 {
		/* display: flex;
  align-items: center;
  justify-content: center; */
		width: 396px;
		height: 69px;
		background: #f6f6f6;
		border-radius: 35px 35px 35px 35px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		/* color: #999999; */
		margin-right: 50px;
		padding: 0 40px;
		text-align: center;
	}

	.content-center-t4 .t4-2 {
		width: 64px;
		height: 64px;
		margin-right: 24px;
	}

	.content-center-t4>.t4-3 {
		width: 64px;
		height: 64px;
	}

	.content-center-t5 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 678px;
		min-height: 50px;
		background: #f2f2f2;
		border-radius: 35px 35px 35px 35px;
		opacity: 1;
		font-size: 24px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		margin-top: 14px;
		margin-bottom: 34px;
	}

	.list-content {
		position: relative;
		height: 715px;
		padding: 5px;
		overflow-y: scroll;
		padding-right: 20px;
		margin-top: 20px;
		/* background: #000; */
		/* scrollbar-width: none; */
		/* Firefox隐藏滚动条 */
		/* -ms-overflow-style: none; */
		/* Internet Explorer 10+隐藏滚动条 */
	}

	.list-content-block {
		position: absolute;
		right: 0;
		top: 420px;
	}

	.list-content-block .el-slider__runway {
		background: rgba(254, 67, 101, 1) !important;
	}

	.list-content-block .el-slider__bar {
		background: rgba(254, 67, 101, 1) !important;
	}

	.list-content-block .el-slider__button {
		border: 2px solid rgba(251, 154, 174, 1) !important;
	}

	.shibxs {
		cursor: pointer;
	}

	.list-content::-webkit-scrollbar {
		/* width: 0; */
		/* Safari,Chrome 隐藏滚动条 */
		/* height: 0; */
		/* Safari,Chrome 隐藏滚动条 */
		/* display: none; */
		/* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
	}

	/*滚动条整体部分,必须要设置*/
	.list-content::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: transparent;
	}

	/*滚动条的轨道*/
	.list-content::-webkit-scrollbar-track {
		background-color: transparent;
	}

	/*滚动条的滑块按钮*/
	.list-content::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(254, 67, 101, 1);
		box-shadow: inset 0 0 5px rgba(254, 67, 101, 1);
	}

	/*滚动条的上下两端的按钮*/
	.list-content::-webkit-scrollbar-button {
		height: 0;
		background-color: rgba(254, 67, 101, 1);
	}

	/* // 两个滚动条的交汇处 */
	.list-content::-webkit-scrollbar-corner {
		background-color: transparent;
	}

	.content-center-list {
		width: 620px;
		min-height: 194px;
		background: #ffffff;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 30px 30px 30px 30px;
		opacity: 1;
		margin-bottom: 32px;
		padding: 17px 12px 20px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content-center-listb {
		background: #fff4f6 !important;
	}

	.content-center-listb2 {
		background: rgba(240, 255, 246, 1) !important;
	}

	.list-left {
		width: 32px;
		height: 32px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #afafaf;
		border-radius: 50%;
		font-size: 20px;
		/* background: red; */
		flex-shrink: 0;
		/* background: red; */
	}

	.list-left-2 {
		width: 32px;
		height: 32px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		/* border: 1px solid #afafaf; */
		border-radius: 50%;
		font-size: 20px;
		/* background: red; */
	}

	.list-left.active {
		width: 32px;
		height: 32px;
		background: rgb(45, 192, 95);
		border: none;
	}

	.content-center-list>.list-center {
		flex: 1;
		height: 100%;
		/* background: red; */
		margin-left: 16px;
		margin-right: 19px;
	}

	.content-center-list>.list-center>.c1 {
		display: flex;
		position: relative;
	}

	.dingwzs {
		position: absolute;
		left: 50px;
		top: 0px;
		width: 313px;
		height: 187px;
		background: #ffffff;
		box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0px 15px;
		padding-top: 12px;
	}

	.dingwzContent {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 19px;
		box-sizing: border-box;
		padding: 0px 31px;
		font-size: 18px;
	}

	.xiala {
		display: flex;
		flex-shrink: 0;
		width: 123px;
		height: 45px;
		background: #ffffff;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #e5e5e5;
		justify-content: space-between;
		align-items: center;
		position: relative;
		font-size: 18px;
		box-sizing: border-box;
		padding: 8px;
		/* background: red; */
		margin-left: 47px;
	}

	.xiala-img {
		flex-shrink: 0;
		width: 28px;
		height: 28px;
		margin-right: 6px;
		flex-shrink: 0;
		margin-left: 6px;
	}

	.xialatc {
		position: absolute;
		top: 48px;
		width: 103px;
		height: 151px;
		background: #f5eded;
		box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 8px 8px 8px 8px;
		z-index: 10;
		box-sizing: border-box;
		padding: 18px 0px;
	}

	.xialatc>.xuanx {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: #726a6a;
	}

	.xuanximg {
		flex-shrink: 0;
		width: 28px;
		height: 28px;
		margin-left: 10px;
	}

	.content-center-list .list-center .c1 .c1-1 {
		font-size: 28px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-right: 24px;
	}

	.content-center-list .list-center .c1 .c1-2 {
		width: 42px;
		height: 42px;
		background: #b4b4b4;
		opacity: 1;
		border-radius: 50%;
	}

	.content-center-list .list-center .c2 {
		font-size: 20px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin-top: 17px;
		margin-bottom: 22px;
		/* background: #000; */
		width: 320px;
		word-break: break-all;
	}

	.content-center-list>.list-center .c3 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.content-center-list>.list-center>.zzs {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.content-center-list>.list-center>.zzs>.zzc_fun {
		margin-left: 59px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.content-center-list>.list-center>.zzs>.zzc_fun>span {
		cursor: pointer;
		font-size: 14px;
		color: #333333;
		margin-right: 32px;
	}

	.content-center-list>.list-right {
		width: 181px;
		height: 61px;
		background: #fe4365;
		border-radius: 31px 31px 31px 31px;
		opacity: 1;
		font-size: 24px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.content-right-h1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content-right-h1>.h1-right {
		display: flex;
		align-items: center;
		font-size: 30px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #fe4365;
	}

	.hs-btn1 {
		width: 120px;
		height: 52px;
		background: #fe4365;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 26px 26px 26px 26px;
		font-size: 22px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 24px;
	}

	.hs-btn2 {
		width: 120px;
		height: 52px;
		background: #b4b4b4;
		border-radius: 26px 26px 26px 26px;
		opacity: 1;
		font-size: 22px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.h1-right-download {
		display: flex;
		align-items: center;
		margin-right: 24px;
	}

	.h1-right-download>img {
		width: 24px;
		height: 24px;
		margin-right: 7px;
	}

	.h1-right-up {
		display: flex;
		align-items: center;
	}

	.h1-right-up>img {
		width: 28px;
		height: 24px;
		margin-right: 7px;
	}

	.content-right-h1>.h1-left {
		font-size: 30px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #333333;
	}

	.content-right-h2 {
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 20px;
	}

	.content-right-h2 span {
		font-size: 26px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		margin-left: 42px;
	}

	.content-right-h3 {
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		padding: 0 50px;
		width: 668px;
		height: 76px;
		background: #ffffff;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.content-right-h4 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.content-right-h4>.h4-left {
		display: flex;
		align-items: center;
	}

	.content-right-h4>.h4-left>.tc {
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin-right: 42px;
	}

	.content-right-h4>.h4-left>.gz {
		font-size: 26px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.content-right-h4>.h4-right {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 174px;
		height: 48px;
		background: #fe4365;
		border-radius: 31px 31px 31px 31px;
		opacity: 1;
		font-size: 30px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.content-right-textarea {
		position: relative;
		border: none;
		outline: none;
		width: 668px;
		height: 805px;
		background: #ffffff;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin-top: 25px;
		font-size: 30px;
		padding: 20px;
		box-sizing: border-box;

		resize: none !important;
	}

	.content-right-textarea::-webkit-scrollbar {
		width: 0 !important;
	}

	.content-right-textarea div {
		border: none;
		outline: none;
		width: 628px;
		height: 785px;
		resize: none !important;
		box-sizing: border-box;

		// padding: 20px;
	}

	.content-right-textarea div::-webkit-scrollbar {
		width: 0 !important;
	}

	.tar::-webkit-scrollbar {
		width: 0 !important;
	}

	.content-right-textarea-choose {
		position: relative;
		border: none;
		outline: none;
		width: 668px;
		height: 805px;
		background: #ffffff;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin-top: 25px;
		padding: 20px;
		font-size: 30px;
		background: red;
	}

	.choosePen {
		position: absolute;
		z-index: 2;
		right: 44px;
		bottom: 216px;
		width: 90px;
		height: 90px;
		border-radius: 50%;
	}

	.cc-slh-2 {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		white-space: normal;
	}

	div {
		white-space: pre-line;
	}

	// p {
	//   white-space: pre-line;
	// }
</style>