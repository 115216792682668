<template>
  <div class="products">
    <div class="products-banner">
      <img :src="product.image_one" class="banner-img" style="" />
      <!-- <img class="banner-img" src="../assets/images/pro-1.png" /> -->

      <div class="banner-absolute">
        <!-- {{product.copy}} -->
        <!-- <div class="h1"> </div> -->
        <!-- {{product.copy_title}} -->
        <!-- <div class="h2"> </div> -->
        <!-- <div class="btn">立即使用</div> -->
      </div>
    </div>

    <!-- <el-row type="flex" class="row-bg">
      <el-col :span="3"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="9"
        ><div class="grid-content bg-purple-light"></div
      ></el-col>
      <el-col :span="9"><div class="grid-content bg-purple"></div></el-col>

      <el-col :span="3"
        ><div class="grid-content bg-purple-light"></div
      ></el-col>
    </el-row> -->

    <div class="products-h1" v-html="product.copy2"></div>

    <div class="products-h2">
      <img class="h2-left" :src="product.image_two" />
      <!-- <img class="h2-left" src="../assets/images/pro-2.png" /> -->
      <div class="h2-right" v-html="product.copy3"></div>
    </div>

    <div class="products-h3">
      <div class="h3-top">我们的优势</div>
      <div class="h3-bottom"></div>
    </div>

    <div class="products-h4">
      <div class="h4-left">
        <div class="left-top" v-html="product.desc1"></div>
        <div class="left-center">
          <div
            class="list l1"
            v-for="(item, index) in product.desc_list"
            :key="index"
          >
            <div class="i"></div>
            {{ item }}
          </div>
        </div>
        <div class="left-bottom" v-html="product.desc2"></div>
      </div>
      <img class="h4-right" :src="product.image_three" />
      <!-- <img class="h4-right" src="../assets/images/pro-3.png" /> -->

    </div>

    <div class="products-h3">
      <div class="h3-top">见证我们的成长</div>
      <div class="h3-bottom"></div>
    </div>

    <!-- list -->
    <div class="products-h5">
      <div class="products-h5-list" v-for="(item, index) in list" :key="index">
        <img class="list-l1" src="../assets/images/grow.png" />
        <div class="list-l2">
          {{ item.content }}
        </div>
        <div class="list-l3">
          <img class="l3-left" :src="item.carouse_image.file_path" />
          <div class="l3-right">
            <div class="r1">{{ item.name }}</div>
            <div class="r2">学生</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      product: "",
      list: [],
    };
  },
  created() {
       document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.9
    this.productInfo();
  },
  mounted() {},
  methods: {
    // 关于
    productInfo() {
      this.$axios.post("about/product", {}).then((res) => {
        console.log(res.data.data);
        if (res.data.code == 200) {
          this.product = res.data.data.product;
          this.list = res.data.data.list;
        } else if (res.data.code == -1) {
          this.$store.commit("exit");
          window.location.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.products-banner {
  width: 1379px;
  height: 468px;
  position: relative;
  background: #333333;
  margin: 0 auto 0;
}

.banner-img {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  display: block;
  width: 100%;
  height: 100%;
  background: #333;
}

.products-banner > .banner-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* width: 1379px;
  height: 468px; */
  text-align: center;
  /* background: red; */
}

.products-banner > .banner-absolute > .h1 {
  font-size: 44px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 86px;
  margin-bottom: 18px;
}

.products-banner > .banner-absolute > .h2 {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.products-banner > .banner-absolute > .btn {
  /* width: 352px;
  height: 48px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #707070;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 350px auto 0;
  width: 352px;
  height: 48px;
  background: #fe4365;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #707070;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.products-h1 {
  width: 709px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  /* margin-top: 80px;
  margin-bottom: 91px; */
  margin: 80px auto 91px;
  text-align: center;
}

.products-h2 {
  display: flex;
  /* background: #000; */
  /* margin-left: */
  /* padding: 0 12.5%; */
  width: 1379px;
  margin: 0 auto;
}

.h2-left {
  width: 731px;
  height: 422px;
  opacity: 1;
  color: #333333;
  background: #333;
  flex-shrink: 0;
}

.h2-left > img {
  outline: none;
  border: none;
}

.h2-right {
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: 32px;
  width: 511px;
}

.products-h3 {
  margin-top: 70px;
  margin-bottom: 76px;
}

.products-h3 > .h3-top {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.products-h3 > .h3-bottom {
  width: 164px;
  height: 4px;
  background: #fe4365;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 0 auto;
}

.products-h4 {
  display: flex;
  /* background: red; */
  /* margin: 0 auto; */
  margin-left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.products-h4 > .h4-left {
  width: 722px;
  margin-left: 265px;
}

.products-h4 > .h4-left > .left-top {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.products-h4 > .h4-left > .left-center {
}

.products-h4 > .h4-left > .left-center > .list {
  min-width: 403px;
  min-height: 32px;
  background: linear-gradient(270deg, #fafafa 0%, #efefef 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  padding: 5px 12px;
}

.l1 {
  margin-top: 21px;
}

.l2 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.l3 {
  margin-bottom: 22px;
}

.products-h4 > .h4-left > .left-center > .list > .i {
  width: 8px;
  height: 8px;
  opacity: 1;
  border: 1px solid #fe4365;
  border-radius: 50%;
  margin-right: 12px;
}

.products-h4 > .h4-left > .left-bottom {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 22px;
}

.products-h4 > .h4-right {
  width: 464px;
  height: 566px;
  background: #ccc;
  margin-left: 60px;
  margin-top: -20px;
}

.products-h5 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1379px;
  margin: 0 auto;
  margin-bottom: 26px;
}

.products-h5 > .products-h5-list {
  width: 346px;
  /* height: 328px; */
  background: #ffffff;
  box-shadow: 3px 3px 20px 1px rgba(254, 67, 101, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 16px 30px 19px;
}

.products-h5 > .products-h5-list > .list-l1 {
  width: 65px;
  height: 53px;
}

.products-h5 > .products-h5-list > .list-l2 {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 30px;
}

.products-h5 > .products-h5-list > .list-l3 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.products-h5 > .products-h5-list > .list-l3 > .l3-left {
  width: 44px;
  height: 44px;
  margin-right: 8px;
  border-radius: 50%;
}

.products-h5 > .products-h5-list > .list-l3 > .l3-right > .r1 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.products-h5 > .products-h5-list > .list-l3 > .l3-right > .r2 {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>

