<template>
  <div class="message" style="min-height: 140vh">
    <div class="message-left">
      <img class="left-l1" :src="personalInfo.face_image" />
      <div class="left-l2">{{ personalInfo.nickName }}</div>
      <div class="left-l3">ID:{{ personalInfo.user_id }}</div>
      <div class="left-l4">个人简介：{{ personalInfo.desc }}</div>
    </div>
    <div class="message-right">
      <div class="right-list" v-for="(item, index) in list" :key="index">
        <img class="list-left" :src="image_path" />
        <div class="list-right">
          <div class="r1">
            <div class="r1-1">{{ item.name }}</div>
            <div class="r1-2">{{ item.create_time }}</div>
          </div>
          <div class="r2">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      personalInfo: "",
      image_path: ''
    };
  },
  created() {
             document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.9


    if (!this.$store.state.user) {
      return this.$router.push("/");
    }
    this.personal();
    this.messageInfo();
  },
  methods: {
    // 个人中心用户信息
    personal() {
      this.$axios
        .post(
          "users/personal",
          {},
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code == 200) {
            this.personalInfo = res.data.data;
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 会员信息
    messageInfo() {
      this.$axios
        .post(
          "system/message",
          {},
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data.data);
          if (res.data.code == 200) {
            this.list = res.data.data.data;
            this.image_path = res.data.data.image_path

            console.log("list:", this.list);
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  display: flex;
  margin-right: 20px;
}

.message-left {
  display: inline;
  white-space: nowrap;
  width: 262px;
  height: 450px;
  max-height: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  /* background: red; */
  padding: 0 20px;
  text-align: center;
  margin-left: 265px;
  margin-right: 20px;
}

.message-left > .left-l1 {
  width: 66px;
  height: 66px;
  background: #ccc;
  opacity: 1;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin: 49px auto 8px;
}

.message-left > .left-l2 {
  font-size: 14px;
  font-family: Hiragino Sans GB-W6, Hiragino Sans GB;
  font-weight: normal;
  color: #333333;
  text-align: center;
  margin-top: 7px;
  margin-bottom: 11px;
}

.message-left > .left-l3 {
  font-size: 14px;
  font-family: Hiragino Sans GB-W6, Hiragino Sans GB;
  font-weight: normal;
  color: #333333;
  text-align: center;
  margin-bottom: 11px;
}

.message-left > .left-l4 {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.message-right {
  width: 1097px;
  min-height: 780px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 80px 36px;
}

.right-list {
  display: flex;
  padding-top: 21px;
  padding-bottom: 29px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.right-list > .list-left {
  width: 48px;
  height: 48px;
  background: #ccc;
  border-radius: 50%;
  margin-right: 15px;
  border: none;
}

.right-list > .list-right {
  width: 355px;

}

.right-list > .list-right > .r1 {
  display: flex;
  // background: #000;
  align-items: center;
  margin-bottom: 16px;
}

.right-list > .list-right > .r1 > .r1-1 {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-right: 24px;

}

.right-list > .list-right > .r1 > .r1-2 {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.r2 {
  font-size: 14px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
}
</style>