<template>
  <div class="user" style="min-height: 140vh">
    <div class="user-left">
      <div class="left-item active">个人中心</div>
      <div class="left-item" @click="navigatorMember()">会员中心</div>
      <div class="left-item" @click="navigatorMessage()">通知消息</div>
      <div class="left-item" @click="navigatorOrder()">个人订单</div>
      <div class="left-item" @click="exit">退出</div>
    </div>
    <div class="user-right">
      <div class="right-r1">
        <div class="r1-avatar-content">
          <img
            v-if="personalInfo.is_vip"
            class="r1-vip"
            src="../assets/images/vip.png"
            alt=""
          />
          <img
            v-if="!personalInfo.is_vip"
            class="r1-vip"
            src="../assets/images/vipno.png"
            alt=""
          />
          <img class="r1-avatar" :src="personalInfo.face_image" />
          <!-- 1男 2女 -->
          <img
            v-if="personalInfo.gender == 1"
            class="r1-sex"
            src="../assets/images/sex-1.png"
            alt=""
          />
          <img
            v-if="personalInfo.gender == 2"
            class="r1-sex"
            src="../assets/images/sex-2.png"
            alt=""
          />
        </div>

        <div class="r1-username">
          {{ personalInfo.nickName }}
        </div>

        <div class="r1-info">
          <div class="info-top">{{ personalInfo.doc_count || "0" }}</div>
          <div class="info-bottom">文档数</div>
        </div>

        <div class="r1-hr"></div>

        <div class="r1-info">
          <div class="info-top">{{ personalInfo.all_timer || "0" }}</div>
          <div class="info-bottom">使用总时长</div>
        </div>

        <div class="r1-hr"></div>

        <div class="r1-info">
          <div class="info-top">{{ listAll.length }}</div>
          <div class="info-bottom">个人订单</div>
        </div>
      </div>

      <div class="right-r2">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="jbxx">
            <div class="r2-info" style="margin-top: 21px">
              <div class="info-1"><span>*</span>用户名：</div>
              <input class="info-2" v-model="username" />
            </div>

            <div class="r2-info">
              <div class="info-1">手机号：</div>
              <div
                style="
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  margin-right: 13px;
                "
              >
                {{ personalInfo.phone }}
              </div>
              <div
                @click="showPopup()"
                class="info-3"
                style="
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #fe4365;
                "
              >
                点击此处更换
              </div>
            </div>

            <div class="r2-info">
              <div class="info-1">性别：</div>
              <el-radio
                v-model="gender"
                label="1"
                text-color="#cccccc"
                fill="#cccccc"
                >男</el-radio
              >
              <el-radio v-model="gender" label="2">女</el-radio>
            </div>

            <div class="r2-info" style="width: 354px; align-items: flex-start">
              <div class="info-1">个人简介：</div>
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="desc"
                show-word-limit
                :autosize="{ minRows: 4, maxRows: 4 }"
              >
              </el-input>
            </div>

            <div class="r2-submit" @click="personalUpdate()">保存</div>
          </el-tab-pane>
          <!-- 2 -->
          <el-tab-pane label="账户安全" name="zhaq">
            <div class="r2-info" style="margin-top: 21px">
              <div class="info-1"><span>*</span>输入手机号：</div>
              <div class="info-2" style="display: flex; align-items: center">
                <input
                  v-model="phone"
                  maxlength="11"
                  style="width: 100%; height: 100%"
                  :input="phoneTestTrim()"
                />
                <img
                  v-if="phoneTest"
                  src="../assets/images/gx.png"
                  style="width: 18px; height: 18px"
                  alt=""
                />
              </div>

              <!-- <img
              v-if="phoneTest"
              src="../assets/images/gx.png"
              style="width: 24px; height: 24px"
              alt=""
            /> -->
            </div>

            <div class="r2-info">
              <div class="info-1"><span>*</span>输入验证码：</div>
              <div class="info-5">
                <input class="info-5-input" v-model="code" />
                <div
                  class="info-5-btn"
                  :class="{ disabled: disabled }"
                  @click="getCode()"
                >
                  {{ countdown ? "重新获取 " + countdown : "获取验证码" }}
                </div>
              </div>
            </div>

            <div class="r2-info">
              <div class="info-1"><span>*</span>新密码：</div>
              <div class="info-2" style="display: flex; align-items: center">
                <!-- 修改 -->
                <input
                  type="password"
                  v-model="password"
                  style="width: 100%; height: 100%"
                  :input="PasswordTrim()"
                />
                <img
                  v-if="PasswordTest"
                  src="../assets/images/gx.png"
                  style="width: 18px; height: 18px"
                  alt=""
                />
              </div>
              <div class="info-3">字母与数字组合，不少于8位</div>
            </div>

            <div class="r2-info">
              <div class="info-1"><span>*</span>确认密码：</div>
              <div class="info-2" style="display: flex; align-items: center">
                <input
                  type="password"
                  v-model="repassword"
                  style="width: 100%; height: 100%"
                  :input="rePasswordTrim()"
                />
                <img
                  v-if="rePasswordTest && this.password == this.repassword"
                  src="../assets/images/gx.png"
                  style="width: 18px; height: 18px"
                  alt=""
                />
              </div>

              <div class="info-3">字母与数字组合，不少于8位</div>
            </div>

            <div class="r2-submit" @click="changePassword()">保存</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div v-show="popup">
      <!--修改密码-->
      <div class="password-popup">
        <img
          class="password-popup-exit"
          @click="closePopup"
          src="../assets/images/exit.png"
        />
        <div class="password-popup-h1">修改手机号</div>

        <div class="password-popup-input" style="margin-top: 26px">
          <input
            type="text"
            placeholder="请输入手机号码"
            maxlength="11"
            v-model="oldPhone"
            :input="oldPhoneTestTrim()"
          />
          <img
            v-if="oldPhoneTest"
            src="../assets/images/gx.png"
            style="width: 24px; height: 24px"
            alt=""
          />
        </div>

        <div class="password-popup-input" style="margin-top: 26px">
          <input v-model="oldCode" type="text" placeholder="请输入验证码" />
          <div
            class="yzm"
            @click="oldGetCode()"
            :class="{ disabled: oldDisabled }"
          >
            {{ oldCountdown ? "重新获取 " + oldCountdown : "获取验证码" }}
          </div>
        </div>

        <!-- 2 -->
        <div class="password-popup-input" style="margin-top: 26px">
          <input
            type="text"
            placeholder="请输入新手机号码"
            maxlength="11"
            v-model="newPhone"
            :input="newPhoneTestTrim()"
          />
          <img
            v-if="newPhoneTest"
            src="../assets/images/gx.png"
            style="width: 24px; height: 24px"
            alt=""
          />
        </div>

        <div class="password-popup-input" style="margin-top: 26px">
          <input v-model="newCode" type="text" placeholder="请输入验证码" />
          <div
            class="yzm"
            @click="newGetCode()"
            :class="{ disabled: newDisabled }"
          >
            {{ newCountdown ? "重新获取 " + newCountdown : "获取验证码" }}
          </div>
        </div>

        <div class="password-popup-btn" @click="updatePhone()">修改</div>
      </div>

      <div class="over" @click="closePopup"></div>
    </div>

    <!-- <router-view /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      popup: false,
      activeName: "jbxx",
      gender: "1",
      // codeTime
      countdown: 0,
      disabled: false,
      // form
      phone: "",
      password: "",
      code: "",
      repassword: "",
      // test
      phoneTest: "",
      //
      desc: "",
      username: "",
      //
      personalInfo: "",
      // changePhone
      oldPhone: "",
      newPhone: "",
      oldPhoneTest: "",
      newPhoneTest: "",
      oldCountdown: 0,
      oldDisabled: false,
      newCountdown: 0,
      newDisabled: false,
      oldCode: "",
      newCode: "",
      // order
      listAll: [],
      PasswordTest: false,
      rePasswordTest: false,
    };
  },
  created() {
            document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.9


    if (!JSON.parse(this.$store.state.user)) {
      this.$router.push("/");
    }
    // console.log(JSON.parse(this.$store.state.user))
    this.personal();
    this.gender = "";
    this.getOrderAll();
  },
  methods: {
   
    PasswordTrim() {
      // /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,24}$/
      var reg = /^[0-9A-Za-z]{8,100}$/;
      // console.log("PasswordTest", reg.test(this.password.trim()));
      // this.password = this.password.replace(/[^\d]/g, "");
      this.PasswordTest = reg.test(this.password.trim());
    },
    rePasswordTrim() {
      // alert('111111111')
      // console.log("333", this.password);
      var reg = /^[0-9A-Za-z]{8,100}$/;
      // console.log("rePasswordTest", reg.test(this.repassword.trim()));
      // this.repassword = this.repassword.replace(/[^\d]/g, "");
      this.rePasswordTest = reg.test(this.repassword.trim());
    },
    exit() {
      this.$store.commit("exit");
      window.location.reload();
    },
    showPopup() {
      this.popup = true;
    },
    closePopup() {
      this.popup = false;
    },
    navigatorMessage() {
      this.$router.push("message");
    },
    navigatorOrder() {
      this.$router.push("order");
    },
    navigatorMember() {
      this.$router.push("member");
    },
    // 订单
    getOrderAll() {
      this.$axios
        .post(
          "order/index",
          {
            type: 0,
            page: this.page,
            listRows: 100,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.listAll = res.data.data.start;
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 修改手机号
    updatePhone() {
      this.$axios
        .post(
          "users/updatePhone",
          {
            phone: this.oldPhone,
            code: this.oldCode,
            rephone: this.newPhone,
            recode: this.newCode,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.$message.success("修改成功");
            this.personal();
            this.closePopup();
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 验证是否是手机号
    phoneTestTrim() {
      var reg = /^1[3456789]\d{9}$/;
      console.log("phoneTest", reg.test(this.phone.trim()));
      this.phone = this.phone.replace(/[^\d]/g, "");
      this.phoneTest = reg.test(this.phone.trim());
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 个人中心修改
    personalUpdate() {
      this.$axios
        .post(
          "users/personalUpdate",
          {
            nickName: this.username,
            desc: this.desc,
            gender: this.gender,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.personal2();
            this.username = "";
            this.desc = "";
            // setTimeout(() => {

            //   window.location.reload();

            // }, 1000)
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 个人中心用户信息
    personal() {
      this.$axios
        .post(
          "users/personal",
          {},
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.personalInfo = res.data.data;
            //  localStorage.setItem("username", res.data.data.nickName);
            //    localStorage.setItem(
            //   "username",
            //   JSON.stringify(res.data.data.nickName)
            // );
            // this.$store.commit('upDataUserName', res.data.data.nickName)

            // window.location.reload();
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 个人中心用户信息
    personal2() {
      this.$axios
        .post(
          "users/personal",
          {},
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.personalInfo = res.data.data;
            localStorage.setItem("username", res.data.data.nickName);
            //    localStorage.setItem(
            //   "username",
            //   JSON.stringify(res.data.data.nickName)
            // );
            this.$store.commit("upDataUserName", res.data.data.nickName);

            window.location.reload();
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 修改密码
    changePassword() {
      this.$axios
        .post("user/updatePass", {
          phone: this.phone,
          code: this.code,
          password: this.password,
          repassword: this.repassword,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            this.$message.success("修改成功");
            // this.closePopup();
            this.phone = "";
            this.code = "";
            this.password = "";
            this.repassword = "";
          } else if (res.data.code == 500) {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 获取验证码
    getCode() {
      if (!this.phoneTest) {
        return this.$message.error("请输入正确的手机号");
      }
      if (this.disabled == true) {
        return;
      }
      this.$axios
        .post("user/code", {
          phone: this.phone,
        })
        .then((res) => {
          console.log(res.data);
          this.disabled = true;
          this.countdown = 60;
          let timer = setInterval(() => {
            this.countdown--;
            if (this.countdown < 1) {
              clearInterval(timer);
              this.disabled = false;
              this.countdown = 0;
            }
          }, 1000);
          if (res.data.code == 500) {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 获取旧验证码
    oldGetCode() {
      if (!this.oldPhoneTest) {
        return this.$message.error("请输入正确的手机号");
      }
      if (this.oldDisabled == true) {
        return;
      }
      this.$axios
        .post("user/code", {
          phone: this.oldPhone,
        })
        .then((res) => {
          console.log(res.data);
          this.oldDisabled = true;
          this.oldCountdown = 60;
          let timer = setInterval(() => {
            this.oldCountdown--;
            if (this.oldCountdown < 1) {
              clearInterval(timer);
              this.oldDisabled = false;
              this.oldCountdown = 0;
            }
          }, 1000);
          if (res.data.code == 500) {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 验证旧手机
    oldPhoneTestTrim() {
      var reg = /^1[3456789]\d{9}$/;
      this.oldPhone = this.oldPhone.replace(/[^\d]/g, "");
      this.oldPhoneTest = reg.test(this.oldPhone.trim());
    },
    // 获取新验证码
    newGetCode() {
      if (!this.newPhoneTest) {
        return this.$message.error("请输入正确的手机号");
      }
      if (this.newDisabled == true) {
        return;
      }
      this.$axios
        .post("user/code", {
          phone: this.newPhone,
        })
        .then((res) => {
          console.log(res.data);
          this.newDisabled = true;
          this.newCountdown = 60;
          let timer = setInterval(() => {
            this.newCountdown--;
            if (this.newCountdown < 1) {
              clearInterval(timer);
              this.newDisabled = false;
              this.newCountdown = 0;
            }
          }, 1000);
          if (res.data.code == 500) {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 验证新手机
    newPhoneTestTrim() {
      var reg = /^1[3456789]\d{9}$/;
      this.newPhone = this.newPhone.replace(/[^\d]/g, "");
      this.newPhoneTest = reg.test(this.newPhone.trim());
    },
  },
};
</script>

<style lang="scss">
.el-radio__input.is-checked + .el-radio__label {
  color: rgba(254, 67, 101, 1) !important;
}
.el-radio__input.is-checked .el-radio__inner {
  background: rgba(254, 67, 101, 1) !important;
  border-color: rgba(254, 67, 101, 1) !important;
}

.user {
  padding: 43px 15% 154px 15%;
  display: flex;
}

.user-left {
  width: 184px;
  height: 285px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.user-left > .left-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 48px;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

.user-left > .left-item.active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 48px;
  background: #fe4365;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  font-size: 12px;
}

.user-right {
  margin-left: 52px;
  padding: calc(102px - 43px);
}

.user-right > .right-r1 {
  display: flex;
  align-items: center;
}

.r1-avatar-content {
  position: relative;
  width: 80px;
  height: 80px;
}

.r1-vip {
  position: absolute;
  width: 33px;
  height: 25px;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.r1-avatar {
  width: 80px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  /* border: 1px solid #707070; */
  border-radius: 50%;
  /* z-index: 2; */
}

.r1-sex {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0px;
}

.user-right > .right-r1 > .r1-username {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 40px;
  margin-right: 230px;
  min-width: 80px;
  white-space: nowrap;
}

.r1-info {
  text-align: center;
}

.r1-info > .info-top {
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #fe4365;
  /* background: red; */
  white-space: nowrap;
  /* display:inline; */
}

.r1-info > .info-bottom {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 13px;
  white-space: nowrap;
  /* display:inline; */
}

.r1-hr {
  width: 0px;
  height: 43px;
  opacity: 0.5;
  border-left: 1px solid rgba(112, 112, 112, 0.5);
  margin: 0 62px;
}

.user-right > .right-r2 {
  margin-top: 53px;
}

.r2-info {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 16px;
}

.r2-info .info-1 {
  min-width: 150px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* text-align: right; */
  /* background: #000; */
  margin-right: 32px;
  margin-left: -50px;
  font-size: 14px !important;
}

.r2-info .info-1 span {
  color: #fe4365;
  font-size: 14px !important;
}

.r2-info .info-2 {
  width: 220px;
  height: 31px;
  border-radius: 0px 0px 0px 0px;
  /* opacity: 0.5; */
  border: 1px solid rgba(112, 112, 112, 0.5);
  /* margin-left: 32px; */
  margin-right: 12px;
  padding: 0 5px;
}

.r2-info > .info-5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  height: 31px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid rgba(112, 112, 112, 0.5);
  margin-right: 12px;
  padding: 0 5px;
}

.r2-info > .info-5 > .info-5-input {
  /* width: calc(220px - 114px); */
  width: 0;
  margin-right: 5px;
  /* background: red; */
  /* background: red; */
  flex: 1;
}

.r2-info > .info-5 > .info-5-btn {
  /* width: 189px; */
  padding: 0 10px;
  height: 24px;
  background: #2dc05f;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.r2-info > .info-5 > .info-5-btn.disabled {
  background: #d9d9d9;
}

.r2-info > .info-3 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.r2-submit {
  width: 130px;
  height: 36px;
  background: #fe4365;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 38px;
  margin-left: 130px;
}

.el-tabs__item {
  /* color: rgba(254, 67, 101, 1) !important; */
  font-size: 16px !important;
}

.el-tabs__item.is-active {
  color: rgba(254, 67, 101, 1) !important;
}

.el-tabs__active-bar {
  background-color: rgba(254, 67, 101, 1) !important;
}

.r2-info .el-textarea__inner {
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 0 !important;
}
</style>