<template>
  <div>
    <!-- 搜索框 -->
    <section>
      <input v-model="value" style="background: #ccc" />
      <span>
        当前要标红的关键字：<b>{{ value }}</b></span
      >
    </section>
    <!-- END -->

    <!-- 内容 -->
    <!-- <div>sgldasgfnadsg阿凡达那里是了</div> -->

    <!-- 列表 -->
    <section v-for="(item, index) in list" :key="index" class="content">
      <div>
        <span v-html="keySign(item.title)"></span>
      </div>
    </section>
    <!-- END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      handleStatus: false,
      // 搜索框的值(您要标红的关键字)
      value: "",
      // 模拟假数据列表
      list: [
        { id: 1, title: "中心化交易所如何用Merkle Tree实现资产储备证明" }
      ],
    };
  },

  methods: {
    /**
     * 标红关键字
     * @description 可自定义HTML结构
     * @param {String} title - 列表标题
     * @return void
     */
    keySign(title) {
      // let s = this.value; // 搜索框的值(您要标红的关键字)
      let s = '中心'
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(/\ +/g, ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<span style="color:#fff;font-weight: bold;color: red;">' +
              e +
              "</span>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },
  },
};
</script>

<style scoped>
/* 样式根据您的需求写即可 */
.content {
  background: rgb(169, 186, 202);
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
}
</style>
