import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user'),
    username: localStorage.getItem('username')
  },
  getters: {
  },
  mutations: {
    exit(state) {
			state.user = ''
			localStorage.removeItem('user');
		},
    upDataUserName(state) {
      state.username = state
      // localStorage.getItem('username') = '222'
    }
  },
  actions: {
  },
  modules: {
  }
})
