<template>
  <div class="opinion" style="height: 140vh;">
    <div class="opinion-h1">意见反馈</div>

    <div class="opinion-h2">
      <div class="h2-2">意见建议:</div>
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="content"
        maxlength="200"
        show-word-limit
        :autosize="{ minRows: 4, maxRows: 4 }"
      >
      </el-input>
    </div>

    <div class="opinion-h3">
      <div class="h3-2">添加图片(选填):</div>

      <el-upload
        class="avatar-uploader"
        action="http://inscription.zyrkeji.cn/api.php/upload/image"
        :headers="{
          token: JSON.parse(this.$store.state.user).token,
        }"
        name="iFile"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <!-- el-icon-plus avatar-uploader-icon -->
        <div v-else class="" style="font-size: 60px;margin-top: -10px;line-height: 60px;color: #ccc">+</div>
      </el-upload>
    </div>

    <div class="opinion-h4">
      <div class="h4-2">联系电话:</div>

      <el-input v-model="phone" placeholder="请输入内容"></el-input>
    </div>

    <div class="opinion-h5" @click="feedback">确认提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
      // 图片
      imageUrl: "",
      file_path: "",
      //
      phone: "",
      // load
      // fullscreenLoading: ''
    };
  },
  created() {
       document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.9
    if (!this.$store.state.user) {
      return this.$router.push("/");
    }
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 0) {
        this.$message.error(res.msg);
      }
      console.log(res);
      this.file_path = res.data.file_path;
      this.file_id = res.data.file_id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      // console.log(file);
      // return
      // this.fullscreenLoading = true;
    },
    // 意见反馈
    feedback() {
      this.$axios
        .post(
          "user.feedback/add",
          {
            content: this.content,
            images: this.file_id,
            phone: this.phone,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.$message.success("反馈成功");
            this.content = "";
            this.file_path = "";
            this.imageUrl = "";
            this.phone = "";
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.opinion-h2 .el-textarea__inner {
  border: none !important;
  width: 658px !important;

  height: 135px !important;
  /* background: #ffffff; */
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // background: red;
}

.el-textarea {
  width: 658px !important;

}

.opinion-h4 .el-input__inner {
  border: none !important;
  width: 250px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}

.avatar-uploader .el-upload {
  /* border: 1px dashed #d9d9d9;
  border-radius: 6px; */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 90px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 30px;
  height: 30px;
  line-height: 178px;
  text-align: center;
}
/* .avatar {
  width: 178px;
  height: 178px;
  display: block;
} */

.opinion {
  padding: 44px 4% 96px 24%;
}

.opinion-h1 {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
  // background: red;
  margin-left: 35px;
}

.opinion-h2 {
  display: flex;
  margin-top: 33px;
  // width: 1500px;
  // background: red;
}

.opinion-h2 > .h2-2 {
  width: 110px;
  text-align: right;
  // margin-right: 130px;
  // background: red;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-right: 12px;
  flex-shrink: 0;
}

.opinion-h3 {
  display: flex;
  margin-top: 24px;
}

.opinion-h3 > .h3-2 {
  // width: 80px;
  // text-align: right;
  // margin-right: 20px;
   width: 110px;
  text-align: right;
  // margin-right: 130px;
  // background: red;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-right: 12px;
  flex-shrink: 0;

}

.opinion-h4 {
  display: flex;
  align-items: center;
  margin-top: 59px;
}

.opinion-h4 > .h4-2 {
  // width: 80px;
  // text-align: right;
  // margin-right: 30px;
   width: 110px;
  text-align: right;
  // margin-right: 130px;
  // background: red;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-right: 20px;
  // background: red;
  flex-shrink: 0;

}

.opinion-h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 40px;
  background: #fe4365;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin: 159px 0 0 0;
  margin-left: 250px;
}
</style>