<template>
  <div>
    <img class="member-banner" :src="MemberImg" />

    <div class="member-m2">
      <div class="m2-list" v-for="(item, index) in memberList" :key="index">
        <div class="list-l1">{{ item.title }}</div>
        <div class="list-l2">¥{{ item.price }}</div>
        <div class="list-l3">原价{{ item.line_price }}元</div>
        <div class="list-l4" @click="showPopup(item, index)">立即购买</div>
      </div>
    </div>

    <div class="member-m3">会员尊享权益</div>

    <div class="member-m4">
      <div class="m4-title">
        <div class="m4-title-left">授权范围对比</div>
        <div class="m4-title-center">普通用户</div>
        <div class="m4-title-right">会员</div>
      </div>

      <div class="m4-content-list">
        <div class="m4-content">
          <div class="m4-content-left">基础功能免费</div>
          <div class="m4-content-center">
            <i
              class="el-icon-check"
              style="color: rgba(254, 67, 101, 1); font-weight: bold"
            ></i>
          </div>
          <div class="m4-content-right">
            <i
              class="el-icon-check"
              style="color: rgba(254, 67, 101, 1); font-weight: bold"
            ></i>
          </div>
        </div>

        <div class="m4-content">
          <div class="m4-content-left">高级功能免费</div>
          <div class="m4-content-center">
            <i
              class="el-icon-check"
              style="color: rgba(254, 67, 101, 1); font-weight: bold"
            ></i>
          </div>
          <div class="m4-content-right">
            <i
              class="el-icon-check"
              style="color: rgba(254, 67, 101, 1); font-weight: bold"
            ></i>
          </div>
        </div>

        <div class="m4-content">
          <div class="m4-content-left">存储</div>
          <div class="m4-content-center">1g</div>
          <div class="m4-content-right">20g</div>
        </div>

        <div class="m4-content">
          <div class="m4-content-left">创建文档数量</div>
          <div class="m4-content-center">{{ package_setting.doc_nor }}个</div>
          <div class="m4-content-right">不限</div>
        </div>

        <div class="m4-content" style="border: none">
          <div class="m4-content-left">可回收文档数量</div>
          <div class="m4-content-center">
            {{ package_setting.recyclable_nor }}个
          </div>
          <div class="m4-content-right">不限</div>
        </div>
      </div>
    </div>

    <!-- <div class="member-m3">文档回收规则</div>

    <div class="member-m5">
      <div class="m5-title">
        <div class="m5-title-left">授权范围对比</div>
        <div class="m5-title-center">1个月</div>
        <div class="m5-title-right">3个月</div>
        <div class="m5-title-right">1年</div>
      </div>

      <div class="m5-content-list">
        <div class="m5-content">
          <div class="m5-content-left">文档延期保存</div>
          <div class="m5-content-center">
            {{ package_setting.month_time }}天
          </div>
          <div class="m5-content-right">{{ package_setting.sea_time }}天</div>
          <div class="m5-content-right">{{ package_setting.year_time }}天</div>
        </div>

        <div class="m5-content">
          <div class="m5-content-left">文档回收顺序</div>
          <div class="m5-content-right2">依据编辑文档时间从早到晚</div>
        </div>

        <div class="m5-content" style="border: none">
          <div class="m5-content-left">回收站回收顺序</div>
          <div class="m5-content-right2">依据进回收站时间从早到晚</div>
        </div>
      </div>
    </div> -->

    <div v-show="popup">
      <!--这里是要展示的内容层-->
      <div class="membar-popup">
        <img
          class="membar-popup-exit"
          @click="closePopup"
          src="../assets/images/exit.png"
        />
        <div class="membar-popup-h1">立即购买</div>
        <div class="membar-popup-h2">{{ memberList[listIndex].title }}</div>
        <div class="membar-popup-h3">¥{{ memberList[listIndex].price }}</div>
        <div class="membar-popup-h4" @click="changeRadio(1)">
          <img src="../assets/images/zfb.png" alt="" />
          <div class="h4-zf">支付宝充值</div>
          <div class="h4-radio">
            <i
              v-if="radio == 1"
              class="el-icon-check"
              style="color: rgb(112, 112, 112, 1); font-size: 12px"
            ></i>
          </div>
        </div>
        <div class="membar-popup-h4" @click="changeRadio(2)">
          <img src="../assets/images/wx.png" alt="" />
          <div class="h4-zf">微信充值</div>
          <div class="h4-radio">
            <i
              v-if="radio == 2"
              class="el-icon-check"
              style="color: rgb(112, 112, 112, 1); font-size: 12px"
            ></i>
          </div>
        </div>

        <div class="membar-popup-h5" @click="navigateRouter('XMember')">
          《提词王会员协议》
        </div>

        <div class="membar-popup-btn">
          <div class="btn1" @click="closePopup()">取消</div>
          <div class="btn2" @click="pay()">确定</div>
        </div>
      </div>
      <!--这里是半透明背景层-->
      <div class="over" @click="closePopup()"></div>
    </div>

    <div v-show="popupPay">
      <div class="popup-pay">
        <img src="../assets/images/pay.png" alt="" />
        <div class="pay-title">支付成功！</div>
      </div>

      <div class="over" @click="closePopupPay()"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popup: false,
      popupPay: false,
      radio: 1,
      MemberImg:'',
      memberList: [
        {
          id: "",
          title: "",
          price: "",
          line_price: "",
        },
      ],
      listIndex: 0,
      package_setting: "",
      loading: true,
      formF: "",
    };
  },
  created() {
    this.getMembers()
    this.memberInfo();
    this.queryPay();
  },
  methods: {
    getMembers(){
      this.$axios
        .post(
          "setting/members",
          { headers: { token: JSON.parse(this.$store.state.user)?.token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.MemberImg = res.data.data.file_path
          } else {
          }
        });
    },
    // 查询是否支付成功
    queryPay() {
      console.log(33333);
      // 获取地址栏
      const href = window.location.href;
      // 截取地址栏参数
      const orderId = href.split("?")[1]?.split("&")[0]?.split("=")[1];
      console.log("orderId", orderId);

      // return

      this.$axios
        .post(
          "order/checkOrder",
          {
            order_no: orderId,
          },
          { headers: { token: JSON.parse(this.$store.state.user)?.token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            // this.memberList = res.data.data.list;
            // this.package_setting = res.data.data.package_setting;
            this.showPopupPay();
            setTimeout(() => {
              this.closePopupPay();
            }, 3000);
          } else {
            // this.$message.error(res.data.msg);
          }
        });
      return;
      const param = {
        orderId: orderId,
        type: "支付宝支付",
      };
      getOrderPayType(param).then((res) => {
        if (res.code === "0000") {
          this.$message.success("支付成功");
        } else {
          this.$message.error("支付失败");
        }
      });
    },
    showPopup(item, index) {
      this.listIndex = index;
      this.popup = true;
    },
    closePopup() {
      this.popup = false;
    },
    showPopupPay(item, index) {
      this.popupPay = true;
    },
    closePopupPay() {
      this.popupPay = false;
    },
    navigateRouter(router) {
      this.$router.push(router);
    },
    changeRadio(i) {
      this.radio = i;
    },
    // 会员信息
    memberInfo() {
      this.$axios.post("package/index", {}).then((res) => {
        console.log(res.data.data);
        if (res.data.code == 200) {
          this.memberList = res.data.data.list;
          this.package_setting = res.data.data.package_setting;
        } else if (res.data.code == -1) {
          this.$store.commit("exit");
          window.location.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 支付
    pay() {
      if (!this.$store.state.user) {
        return this.$message.error("请先登录");
      }
      this.$axios
        .post(
          "pay/index",
          {
            package_id: this.memberList[this.listIndex].id,
            type: this.radio,
            // return_url: "http://localhost:8080/#/member",
            return_url: "https://www.ticiwang.cn/#/member",
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            // this.formF = res.data.data
            document.querySelector("body").innerHTML = res.data.data;
            document.forms[0].submit();
            // this.memberList = res.data.data.list;
            // this.package_setting = res.data.data.package_setting;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.popup-pay {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 300px;
  height: 300px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #707070;
}

.popup-pay img {
  display: block;
  width: 85px;
  height: 85px;
  margin: 70px auto 24px;
}

.pay-title {
  font-size: 36px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.membar-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 692px;
  height: 541px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #707070;
  padding: 31px 48px 34px;
}

.membar-popup > .membar-popup-exit {
  position: absolute;
  top: 37px;
  left: 31px;
  width: 25px;
  height: 25px;
}

.membar-popup > .membar-popup-h1 {
  font-size: 26px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.membar-popup > .membar-popup-h2 {
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-top: 55px;
  margin-bottom: 6px;
  text-align: center;
}

.membar-popup > .membar-popup-h3 {
  font-size: 56px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #fe4365;
  text-align: center;
}

.membar-popup > .membar-popup-h4 {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 40px;
}

.membar-popup > .membar-popup-h4 > img {
  width: 24px;
  height: 24px;
}

.membar-popup > .membar-popup-h4 > .h4-zf {
  margin-left: 12px;
  flex: 1;
}

.membar-popup > .membar-popup-h4 > .h4-radio {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 112, 112, 1);
  border-radius: 50%;
}

.membar-popup > .membar-popup-h5 {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #3359d8;
  margin-top: 56px;
  margin-bottom: 11px;
}

.membar-popup-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn1 {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #707070;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background: #fe4365;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;

  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #111111;
}

.member-banner {
  /* display: flex; */
  display: block;
  margin: 0 auto;
  width: 1379px;
  height: 297px;
  /* background: #211a42; */
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.member-m2 {
  display: flex;
  justify-content: space-between;
  // padding: 0 20%;
  width: 1379px;
  margin: 0 auto;
  margin-top: 34px;
  margin-bottom: 50px;
  // background: red;
}

.member-m2 > .m2-list {
  width: 326px;
  /* height: 324px; */
  padding: 30px 15px 30px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}

.member-m2 > .m2-list > .list-l1 {
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.member-m2 > .m2-list > .list-l2 {
  font-size: 56px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #fe4365;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.member-m2 > .m2-list > .list-l3 {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #bfbfbf;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: line-through;
}

.member-m2 > .m2-list > .list-l4 {
  width: 298px;
  height: 49px;
  background: #fe4365;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member-m3 {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.member-m4 {
  width: 1184px;
  margin: 40px auto;
}

.member-m4 > .m4-title {
  width: 100%;
  height: 83px;
  background: #fff6f8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.member-m4 > .m4-title .m4-title-left {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  flex: 3;
}

.member-m4 > .m4-title .m4-title-center {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 250px;
  text-align: center;
}

.member-m4 > .m4-title .m4-title-right {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 250px;
  text-align: center;
}

.m4-content-list {
  /* background: #ffffff; */
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid rgba(112, 112, 112, 0.2);
  margin-top: 10px;
}

.member-m4 > .m4-content-list > .m4-content {
  width: 100%;
  height: 83px;
  /* background: #fff6f8; */
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* background: #ffffff; */
  /* border-radius: 20px 20px 20px 20px; */
  opacity: 1;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  /* border-bottom: 1px solid #707070; */
}

.member-m4 > .m4-content-list > .m4-content > .m4-content-left {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  flex: 3;
}

.member-m4 > .m4-content-list > .m4-content .m4-content-center {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 250px;
  text-align: center;
}

.member-m4 > .m4-content-list > .m4-content .m4-content-right {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 250px;
  text-align: center;
}

.member-m5 {
  width: 1184px;
  margin: 40px auto 164px;
}

.member-m5 > .m5-title {
  width: 100%;
  height: 83px;
  background: #fff6f8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.member-m5 > .m5-title .m5-title-left {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  flex: 3;
  /* background: red; */
  /* font-weight: bold; */
}

.member-m5 > .m5-title .m5-title-center {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 200px;
  text-align: center;
}

.member-m5 > .m5-title .m5-title-right {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 200px;
  text-align: center;
}

.m5-content-list {
  /* background: #ffffff; */
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid rgba(112, 112, 112, 0.2);
  margin-top: 10px;
}

.member-m5 > .m5-content-list > .m5-content {
  width: 100%;
  height: 83px;
  /* background: #fff6f8; */
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* background: #ffffff; */
  /* border-radius: 20px 20px 20px 20px; */
  opacity: 1;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  /* border-bottom: 1px solid #707070; */
}

.member-m5 > .m5-content-list > .m5-content > .m5-content-left {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  flex: 3;
}

.member-m5 > .m5-content-list > .m5-content .m5-content-center {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 200px;
  text-align: center;
}

.member-m5 > .m5-content-list > .m5-content .m5-content-right {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 200px;
  text-align: center;
}

.member-m5 > .m5-content-list > .m5-content .m5-content-right2 {
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  width: 790px;
  text-align: center;
}
</style>