<template>
  <div class="firm" >
    <img class="firm-banner" :src="about.image_one">

    <div class="firm-h2">
      <div class="firm-h2-bg"></div>
      <div class="firm-h2-1">关于我们</div>
      <div class="firm-h2-2">Brand Profile</div>
      <div class="firm-h2-3">
        {{ about.desc }}
      </div>
    </div>

    <div class="firm-h3">
      <div class="firm-h3-bg"></div>
      <div class="firm-h3-1">品牌理念</div>
      <div class="firm-h3-2">Brand Profile</div>
      <!--  -->
      <div class="firm-h3-3">
        <!-- <img class="h3-3-left" src="" /> -->
        <img class="h3-3-left" :src="about.image_two" />
        <div class="h3-3-right">
          {{ about.market }}
        </div>
      </div>

      <!--  -->
      <div class="firm-h3-4">
        <div class="h3-4-left">
          {{ about.taletl }}
        </div>
        <img class="h3-4-right" :src="about.image_three" />
      </div>

      <!--  -->
      <div class="firm-h3-3">
        <img class="h3-3-left" :src="about.image_four" />
        <div class="h3-3-right">
          {{ about.technology }}
        </div>
      </div>
    </div>

    <div class="firm-h4">
      <div class="firm-h4-bg"></div>
      <div class="firm-h4-1">品牌历程</div>
      <div class="firm-h4-2">Brand Profile</div>
      <!--  -->
      <div class="firm-h4-4" v-for="(item, index) in list" :key="index">
        <div class="title">
          <div
            style="
              width: 10px;
              height: 10px;
              background: #333333;
              opacity: 1;
              border-radius: 50%;
              margin-right: 11px;
            "
          ></div>
          <div>{{item.title}}</div>
        </div>
        <div class="h4-4-content">
          {{item.content}}
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  created() {
       document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.9
    this.aboutInfo();
  },
  data() {
    return {
      about: "",
      list: [],
    };
  },
  methods: {
    // 关于
    aboutInfo() {
      this.$axios.post("about/about", {}).then((res) => {
        console.log(res.data.data);
        if (res.data.code == 200) {
          this.about = res.data.data.about;
          this.list = res.data.data.list;
        } else if (res.data.code == -1) {
          this.$store.commit("exit");
          window.location.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.firm {
  width: 100vw;
  overflow: hidden;
  /* background: red; */
  margin: 0 auto;
}

.firm-banner {
  width:1287px;
  // height: 636px;
  /* background: #211a42; */
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  /* border: 1px solid #707070; */
  object-fit: contain;
  position: relative;
  left:50%;
  transform: translateX(-50%);
}

.firm-h2 {
  margin-top: 76px;
  min-height: 464px;
}

.firm-h2 > .firm-h2-bg {
  width: 443px;
  height: 464px;
  background: #dcdcdc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.firm-h2 > .firm-h2-1 {
  font-size: 48px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-top: 40px;
}

.firm-h2 > .firm-h2-2 {
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.firm-h2 > .firm-h2-3 {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
  text-align: left;
  width: 677px;
  margin: 20px auto 0px;
}

.firm-h2 > .firm-h2-4 {
  margin: 0 auto;
  /* background: red; */
  width: 613px;
  margin-top: 62px;
}

.firm-h2 > .firm-h2-4 > .title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: -20px;
}

.firm-h2 > .firm-h2-4 > .h2-4-content {
  width: 613px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
}

.firm-h3 {
  margin-top: 76px;
  min-height: 1825px;
}

.firm-h3 > .firm-h3-bg {
  width: 443px;
  height: 1825px;
  background: #dcdcdc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.firm-h3 > .firm-h3-1 {
  font-size: 48px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-top: 40px;
}

.firm-h3 > .firm-h3-2 {
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.firm-h3 > .firm-h3-3 {
  /* width: 1500px; */
  display: flex;
  margin-top: 71px;
  /* background: red; */
  justify-content: center;
  margin-left: -290px;

}

.firm-h3 > .firm-h3-3 > .h3-3-left {
  width: 1173px;
  height: 505px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  background: #ccc;
}

.firm-h3 > .firm-h3-3 > .h3-3-right {
  width: 352px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 84px;
  margin-top: 105px;
}

.firm-h3 > .firm-h3-4 {
  display: flex;
  margin-top: 71px;
  justify-content: center;
  /* background: red; */
  margin-right: -320px;

}

.firm-h3 > .firm-h3-4 > .h3-4-left {
  width: 352px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-right: 84px;
  margin-top: 105px;
  /* flex: 1; */
  margin-left: 293px;
  text-align: right;
}

.firm-h3 > .firm-h3-4 > .h3-4-right {
  width: 1173px;
  height: 505px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  background: #ccc;
  margin-right: 320px;
}

.firm-h4 {
  margin-top: 76px;
  min-height: 464px;
  margin-bottom: 158px;
}

.firm-h4 > .firm-h4-bg {
  width: 443px;
  height: 783px;
  background: #dcdcdc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.firm-h4 > .firm-h4-1 {
  font-size: 48px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-top: 40px;
  
}

.firm-h4 > .firm-h4-2 {
  font-size: 32px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.firm-h4 > .firm-h4-3 {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
  text-align: left;
  width: 677px;
  margin: 20px auto 0px;
}

.firm-h4 > .firm-h4-4 {
  margin: 0 auto;
  /* background: red; */
  width: 613px;
  margin-top: 62px;
}

.firm-h4 > .firm-h4-4 > .title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: -20px;
}

.firm-h4 > .firm-h4-4 > .h4-4-content {
  width: 613px;
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 20px;
}
</style>