<template>
  <div class="order-container">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部订单" name="0"> </el-tab-pane>
        <el-tab-pane label="进行中" name="1"></el-tab-pane>
        <el-tab-pane label="已完成" name="2"></el-tab-pane>
      </el-tabs>
    </div>

    <!-- 进行中 -->
    <div v-if="listIng.length > 0">
      <div class="continue" v-if="activeName == 0 || activeName == 1">
        <div class="continue-left">进行中</div>
        <div class="continue-right">
          <div class="list" v-for="(item, index) in listIng" :key="index">
            <div class="r1">
              <div class="r1-left">订单编号：{{ item.order_no }}</div>
              <div class="r1-right">进行中</div>
            </div>
            <div class="r2">
              题词王{{ item.unit }}会员：<span class="span1">{{ item.price }}</span>
              <span class="span2">元/{{ item.unit }}</span>
            </div>
            <div class="r3">
              会员起止时间：<span class="span1"
                >{{ item.start_time }}-{{ item.end_time }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 完成 -->
    <div v-if="listWc.length > 0">
      <div class="wc" v-if="activeName == 0 || activeName == 2">
        <div class="wc-left">完成</div>
        <div class="wc-right">
          <div class="list" v-for="(item, index) in listWc" :key="index">
            <div class="r1">
              <div class="r1-left">订单编号：{{ item.order_no }}</div>
              <div class="r1-right">已完成</div>
            </div>
            <div class="r2">
              题词王{{ item.unit }}会员：<span class="span1">{{ item.price }}</span>
              <span class="span2">元/{{ item.unit }}</span>
            </div>
            <div class="r3">
              会员起止时间：<span class="span1"
                >{{ item.start_time }}-{{ item.end_time }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 0,
      listIng: [],
      listWc: [],
      page: 1,
    };
  },
  created() {
    this.getOrderIng();
    this.getOrderWc();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 订单进行中
    getOrderIng() {
      this.$axios
        .post(
          "order/index",
          {
            type: 1,
            page: this.page,
            listRows: 100,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.listIng = res.data.data.start;
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 订单已完成
    getOrderWc() {
      this.$axios
        .post(
          "order/index",
          {
            type: 2,
            page: this.page,
            listRows: 100,
          },
          { headers: { token: JSON.parse(this.$store.state.user).token } }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.code == 200) {
            this.listWc = res.data.data.start;
          } else if (res.data.code == -1) {
            this.$store.commit("exit");
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.order-container .el-tabs__item {
  /* color: rgba(254, 67, 101, 1) !important; */
  font-size: 28px !important;
}

.order-container .el-tabs__item.is-active {
  color: rgba(254, 67, 101, 1) !important;
  font-weight: bold !important;
}

.order-container {
  min-height: 80vh;
  padding: 60px 15% 119px;
}

.tabs {
  width: 1000px;
}

.continue {
  display: flex;
  // padding: 5px;

  .el-tabs__item {
    // font-size: 300px !important;
  }

  .continue-left {
    width: 59px;
    // height: 195px;
    background: #fd607c;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 36px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .continue-right {
    .list {
      width: 914px;
      height: 195px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0px 8px 8px 0px;
      opacity: 1;
      padding: 16px 24px 19px 36px;
      margin-bottom: 16px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    .r1 {
      display: flex;
      justify-content: space-between;

      .r1-left {
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .r1-right {
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4365;
      }
    }

    .r2 {
      font-size: 28px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 20px;
      margin-bottom: 20px;

      .span1 {
        color: rgba(254, 67, 101, 1);
      }

      .span2 {
        font-size: 16px;
      }
    }

    .r3 {
      font-size: 28px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      .span1 {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.wc {
  display: flex;
  // padding: 5px;
  margin-top: 16px;

  .wc-left {
    width: 59px;
    // height: 195px;
    background: rgba(41, 51, 60, 1);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 36px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .wc-right {
    .list {
      width: 914px;
      height: 195px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0px 8px 8px 0px;
      opacity: 1;
      padding: 16px 24px 19px 36px;
      margin-bottom: 16px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }

    .r1 {
      display: flex;
      justify-content: space-between;

      .r1-left {
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .r1-right {
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
    }

    .r2 {
      font-size: 28px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 20px;
      margin-bottom: 20px;

      .span1 {
        color: rgba(254, 67, 101, 1);
      }

      .span2 {
        font-size: 16px;
      }
    }

    .r3 {
      font-size: 28px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      .span1 {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>