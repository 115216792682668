<template>
  <div class="XPay">
    
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  created() {
    this.cancellation();
  },
  data() {
    return {
      content: ""
    };
  },
  methods: {
    // 关于
    cancellation() {
      this.$axios.post("setting/about", {}).then((res) => {
        console.log(res.data.data);
        if (res.data.code == 200) {
          this.content = res.data.data.content;
        //   this.list = res.data.data.list;
        }  else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.XPay {
  width: 100vw;
  overflow: hidden;
  min-height: 90vh;
  padding: 36px 20% 180px;
}

</style>