<template>
	<div id="app" class="app">
		<!-- <div class="test" @click="test">test</div> -->
		<div class="nav">
			<div class="navcontent" style="width: 1872px;display: flex;justifly">
				<div class="nav-left">
					<img class="nav-logo" @click="navigateRouter('/')" src="./assets/images/logo.png" />
					<div class="nav-itemList">
						<div class="nav-item" @click="navigateRouter('member')">
							会员中心
						</div>
						<div class="nav-item" @click="navigateRouter('opinion')">
							意见反馈
						</div>
						<div class="nav-item" @click="navigateRouter('products')">
							产品介绍
						</div>
						<div class="nav-item" @click="navigateRouter('firm')">公司介绍</div>
					</div>
				</div>
				<div class="nav-right">
					<div class="nav-message">
						<img class="nav-message-icon" src="./assets/images/message.png" alt="" />
						<div class="nav-message-text" @click="navigateRouter('message')">
							通知消息
						</div>
					</div>
					<!-- 已登录 -->
					<div class="nav-user" v-if="isLogin" @click="navigateRouter('user')">
						<img class="nav-user-icon-2" :src="JSON.parse(this.$store.state.user).face_url" />
						<div class="nav-user-text">
							{{ this.$store.state.username }}
						</div>
					</div>
					<!-- 未登录 -->
					<div class="nav-user" v-else @click="showPopup">
						<img class="nav-user-icon-2" src="./assets/images/user.png" />
						<div class="nav-user-text">登录/注册</div>
					</div>
				</div>
			</div>
		</div>

		<div v-show="popup">
			<!--手机登录-->
			<div class="login-popup" v-if="popupType == 'phone'">
				<img class="login-popup-exit" @click="closePopup" src="./assets/images/exit.png" />
				<div class="login-popup-h1">手机密码登录</div>
				<div class="login-popup-h2">
					<div class="h2-top">
						<input type="text" class="input-text" v-model="phone" placeholder="请输入手机号码" maxlength="11"
							:input="phoneTestTrim()" />
						<img v-if="phoneTest" src="./assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
					</div>
					<div class="h2-bottom">
						<input type="password" class="input-text" v-model="password" placeholder="请输入手机密码" />
					</div>
				</div>
				<div class="login-popup-h3">
					<div class="h3-left" @click="changeType('password')">忘记密码？</div>
					<div class="h3-right" @click="changeType('yzm')">手机验证码登录</div>
				</div>

				<div class="login-popup-h4">
					<div class="h4-left" @click="changeFree()">
						<div class="el-icon-check" v-if="is_free == 1"></div>
					</div>
					<div class="h4-right">7天内免登录</div>
				</div>

				<div class="login-popup-h5" @click="phonePasswordLogin()">立即登录</div>
				<div class="login-popup-h6">
					点击立即登录按钮视为阅读并同意
					<span @click="navigateRouter('XPay')">《用户协议》</span>及<span
						@click="navigateRouter('XPrivacy')">《隐私政策》</span>
				</div>
			</div>

			<!--修改密码-->
			<div class="password-popup" v-else-if="popupType == 'password'">
				<img class="password-popup-exit" @click="closePopup" src="./assets/images/exit.png" />
				<div class="password-popup-h1">修改密码</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input type="text" class="input-text" placeholder="请输入手机号码" maxlength="11" v-model="phone" :input="phoneTestTrim()" />
					<img v-if="phoneTest" src="./assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="code" type="text" placeholder="请输入验证码" />
					<div class="yzm" @click="getCode()" :class="{ disabled: disabled }">
						{{ countdown ? "重新获取 " + countdown : "获取验证码" }}
					</div>
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="password" class="input-text" type="password" placeholder="请输入新密码，字母与数字组合不少于8位" />
				</div>

				<div class="password-popup-input" style="margin-top: 26px">
					<input v-model="repassword" class="input-text" type="password" placeholder="请确认新密码" />
				</div>
				<div class="password-popup-btn" @click="changePassword()">修改</div>
			</div>

			<!--验证码登录 默认-->
			<div class="login-popup" v-if="popupType == 'yzm'">
				<img class="login-popup-exit" @click="closePopup" src="./assets/images/exit.png" />
				<div class="login-popup-h1">手机验证码快捷登录</div>
				<div class="login-popup-h2">
					<div class="h2-top">
						<input type="text" v-model="phone" class="input-text" placeholder="请输入手机号码" maxlength="11"
							:input="phoneTestTrim()" />
						<img v-if="phoneTest" src="./assets/images/gx.png" style="width: 24px; height: 24px" alt="" />
					</div>
					<div class="h2-bottom">
						<input type="text" class="input-text"  v-model="code" placeholder="请输入验证码" />
						<div class="h2-bottom-btn" @click="getCode()" :class="{ disabled: disabled }">
							{{ countdown ? "重新获取 " + countdown : "获取验证码" }}
						</div>
					</div>
				</div>
				<div class="login-popup-h3">
					<div class="h3-left" @click="changeType('password')"></div>
					<div class="h3-right" @click="changeType('phone')">密码登录</div>
				</div>

				<div class="login-popup-h4">
					<div class="h4-left" @click="changeFree()">
						<div class="el-icon-check" v-if="is_free == 1"></div>
					</div>
					<div class="h4-right">7天内免登录</div>
				</div>

				<div class="login-popup-h5" @click="phoneCodeLogin">立即登录</div>
				<div class="login-popup-h6">
					点击立即登录按钮视为阅读并同意<span @click="navigateRouter('XPay')">《用户协议》</span>及<span
						@click="navigateRouter('XPrivacy')">《隐私政策》</span>
				</div>
			</div>

			<!--这里是半透明背景层-->
			<div class="over" @click="closePopup"></div>
		</div>
		<router-view />

		<!-- <div class="app-bottom-fixed"></div> -->

		<div class="app-bottom">
			<div class="bottom-content">
				<div class="bottom-left">
					<div class="left-l1">
						<img src="../src/assets/images/bottom-logo.png" alt="" />
						提词王 | 在线提词器
					</div>
					<div class="left-l2">
						一个非常好用的在线提词器，支持多文档上传下载，为广大用户提供方便快捷的提词服务。手机版本的提词器正在研发中，期待不久和你们见面！
					</div>
				</div>
				<div class="bottom-center">
					<div class="center-c1">
						您可以向提词王 | 在线提词器发送邮件或拨打电话获得帮助
					</div>
					<div class="center-c2">企业邮箱：blackfoxs@dingtalk.com</div>
					<div class="center-c2">
						公司地址:浙江省金华市兰溪市云山街道工人路159号d3-304-a室
					</div>
					<div class="center-c3">
						固定电话 0579-83105201 (服务时间：09：00-18：00）
					</div>
					
					<div class="center-c4">
						红狐（金华市）网络科技有限责任公司 Copyright © 2017-2021 版权所有
						|浙ICP备2022013644号 | 平台知识产权保护
					</div>
				</div>
				<div class="bottom-right">
					<div class="right-r1" @click="navigateRouter('firm')">关于我们</div>
					<div class="right-r2" @click="navigateRouter('XPrivacy')">
						隐私策略
					</div>
					<div class="right-r3" @click="navigateRouter('XPay')">服务协议</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	console.log = function() {};
	import Vue from "vue";
	import {
		mapGetters,
		mapActions
	} from "vuex";
	export default {
		name: "app",
		data() {
			return {
				userinfo: localStorage.getItem("user"),
				isLogin: localStorage.getItem("user"),
				popup: false,
				popupType: "yzm",
				// codeTime
				countdown: 0,
				disabled: false,
				// form
				phone: "",
				password: "",
				code: "",
				is_free: "0",
				repassword: "",
				// test
				phoneTest: "",
			};
		},
		watch: {},
		computed: {},
		created() {
			// setInterval(() => {
			document.body.style.zoom = 0.67;
			// document.getElementsByTagName('body')[0].style.zoom = 0.67 * 0.75
			// alert(444)
			// }, 200)
			// alert(444)
			// this.ajaxRequest();
			this.addTimer();
			if (!this.$store.state.user) {
				this.showPopup();
			}

		},
		methods: {

			// 用户时长累计
			addTimer() {
				setInterval(() => {
					if (this.$store.state.user) {
						this.$axios
							.post(
								"users/addTimer", {
									timer: 120,
								}, {
									headers: {
										token: JSON.parse(this.$store.state.user).token
									}
								}
							)
							.then((res) => {
								// console.log(res.data.data);
								if (res.data.code == 200) {} else if (res.data.code == -1) {
									this.$store.commit("exit");
									window.location.reload();
								} else {
									this.$message.error(res.data.msg);
								}
							});
					}
				}, 120000);
			},
			test() {
				console.log(JSON.parse(this.$store.state.user).nickName);
			},
			showPopup() {
				this.popup = true;
				this.popupType = "yzm";
			},
			closePopup() {
				this.popup = false;
			},
			navigateRouter(router) {
				if (router == "/" && !this.$store.state.user) {
					return this.showPopup();
				}
				if (router == "opinion" && !this.$store.state.user) {
					return this.showPopup();
				}
				if (router == "message" && !this.$store.state.user) {
					return this.showPopup();
				}
				this.$router.push(router);
				this.closePopup();
			},
			changeType(type) {
				this.popupType = type;
			},
			changeFree() {
				if (this.is_free == 0) {
					this.is_free = 1;
				} else {
					this.is_free = 0;
				}
			},
			// 验证是否是手机号
			phoneTestTrim() {
				// /^1[3456789]\d{9}$/
				// /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/
				var reg = /^1[3456789]\d{9}$/;
				console.log("phoneTest", reg.test(this.phone.trim()));
				this.phone = this.phone.replace(/[^\d]/g, "");
				this.phoneTest = reg.test(this.phone.trim());
			},
			// 手机密码登录
			phonePasswordLogin() {
				this.$axios
					.post("user/passLogin", {
						phone: this.phone,
						password: this.password,
						// //1为7天 0为1天
						is_free: this.is_free,
					})
					.then((res) => {
						// console.log(res.data.data.nickName.toString());
						if (res.data.code == 200) {
							localStorage.setItem("user", JSON.stringify(res.data.data));
							localStorage.setItem("username", res.data.data.nickName);
							// localStorage.setItem("username", '1234');
							this.closePopup();
							this.phone = "";
							this.password = "";
							this.is_free = 0;
							window.location.reload();
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 手机验证码登录
			phoneCodeLogin() {
				this.$axios
					.post("user/phoneLogin", {
						phone: this.phone,
						code: this.code,
						// //1为7天 0为1天
						is_free: this.is_free,
					})
					.then((res) => {
						// console.log(res.data.data.nickName.toString());
						if (res.data.code == 200) {
							localStorage.setItem("user", JSON.stringify(res.data.data.user));
							localStorage.setItem("username", res.data.data.user.nickName);
							// localStorage.setItem("username", '1234');

							this.closePopup();
							this.phone = "";
							this.password = "";
							this.code = "";
							this.is_free = 0;
							window.location.reload();
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 修改密码
			changePassword() {
				this.$axios
					.post("user/updatePass", {
						phone: this.phone,
						code: this.code,
						password: this.password,
						repassword: this.repassword,
					})
					.then((res) => {
						console.log(res.data);
						if (res.data.code == 200) {
							this.$message.success("修改成功");
							this.closePopup();
							this.phone = "";
							this.code = "";
							this.password = "";
							this.repassword = "";
						} else if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 获取验证码
			getCode() {
				if (!this.phoneTest) {
					return this.$message.error("请输入正确的手机号");
				}
				if (this.disabled == true) {
					return;
				}
				this.$axios
					.post("user/code", {
						phone: this.phone,
					})
					.then((res) => {
						console.log(res.data);
						this.disabled = true;
						this.countdown = 60;
						let timer = setInterval(() => {
							this.countdown--;
							if (this.countdown < 1) {
								clearInterval(timer);
								this.disabled = false;
								this.countdown = 0;
							}
						}, 1000);
						if (res.data.code == 500) {
							this.$message.error(res.data.msg);
						}
					});
			},
		},
	};
</script>
<style>
	.input-text::-webkit-input-placeholder{
		color: #999;
	}
	.input-text:-moz-placeholder{
		color: #999;
	}
	.input-text::-moz-placeholder{
		color: #999;
	}
	.input-text:-ms-input-placeholder{
		color: #999;
	}

</style>

<style lang="scss">
	@font-face {
		font-family: PingFang SC-Regular;
		src: url("../src/assets/font/PingFang Regular.ttf");
	}

	@font-face {
		font-family: PB;
		src: url("../src/assets/font/PingFang Bold.ttf");
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: "PingFang SC-Regular" !important;
	}

	button,
	input {
		border: none;
		outline: none;
	}

	div {
		// user-select: none;
	}

	img {
		flex-shrink: 0;
	}

	body {
		overflow-x: hidden;
		-webkit-tap-highlight-color: transparent;
		/* overflow: hidden; */
	}

	body::-webkit-scrollbar {
		display: none;
	}

	.el-textarea__inner {
		resize: none !important;
	}

	.nav {
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
		width: 100%;
		height: 197px;
		background: #fe4365;
		opacity: 1;
		// width: 1872px;
	}

	.navcontent {
		width: 1872px;
		justify-content: space-between;
		margin: 0 auto;
		// background: #000;
	}

	.nav-bottom {
		width: 100%;
		height: calc(287px - 197px);
		background: #fe4365;
		opacity: 1;
	}

	.nav-left {
		display: flex;
	}

	.nav-logo {
		width: 287px;
		height: 113px;
		/* margin-top: -24px; */
		margin-left: 4px;
		margin-top: 30.6px;
	}

	.nav-itemList {
		display: flex;
		/* align-items: center; */
		/* background: red; */
	}

	.nav-item {
		font-size: 28px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		line-height: 0px;
		margin-top: 78px;
		margin-left: 50px;
		height: 40px;
		line-height: 40px;
	}

	.nav-right {
		display: flex;
		/* background: red; */
		height: 40px;
		line-height: 40px;
		margin-top: 78px;
	}

	.nav-message {
		display: flex;
		align-items: center;
		margin-right: 50px;
	}

	.nav-message-icon {
		width: 25.25px;
		height: 28.16px;
		margin-right: 8px;
	}

	.nav-message-text {
		font-size: 28px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		line-height: 0px;
	}

	.nav-user {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}

	.nav-user-icon {
		width: 25.25px;
		height: 28.16px;
		margin-right: 8px;
		background: #000;
		border-radius: 50%;
	}

	.nav-user-icon-2 {
		width: 28px;
		height: 28px;
		margin-right: 8px;
		/* background: #000; */
		border-radius: 50%;
	}

	.nav-user-text {
		font-size: 28px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		line-height: 0px;
	}

	.login-popup {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		width: 488px;
		/* height: 430px;  */
		background: #ffffff;
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		z-index: 1000;
		padding: 32px 40px 70px;
		overflow: hidden;
	}

	.login-popup>.login-popup-exit {
		position: absolute;
		top: 24px;
		left: 24px;
		width: 22px;
		height: 22px;
	}

	.login-popup>.login-popup-h1 {
		font-size: 20px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #000000;
		text-align: center;
	}

	.login-popup>.login-popup-h2 {
		// width: 408px;
		// height: 100px;
		border-radius: 0px 0px 0px 0px;
		border: 1px solid rgb(112, 112, 112, 0.5);
		margin-top: 35px;
	}

	.login-popup>.login-popup-h2 ::-webkit-input-placeholder {
		font-size: 18px;
	}

	.login-popup>.login-popup-h2 ::-moz-placeholder {
		font-size: 18px;
	}

	.login-popup>.login-popup-h2 :-ms-input-placeholder {
		font-size: 18px;
	}

	.login-popup>.login-popup-h2>.h2-top {
		width: 100%;
		height: 60px;
		border-bottom: 1px solid rgb(112, 112, 112, 0.5);
		padding: 0px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* background: #000; */
	}

	.login-popup>.login-popup-h2>.h2-top>input {
		width: 100%;
		height: 100%;
		font-size: 20px;
		font-weight: bold;
	}

	

	.login-popup>.login-popup-h2>.h2-bottom {
		height: 60px;
		width: 100%;
		padding: 0px 8px;
		// background: red;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;

	}

	.login-popup>.login-popup-h2>.h2-bottom>input {
		flex: 1;
		height: 100%;

	}

	.login-popup>.login-popup-h2>.h2-bottom>.h2-bottom-btn {
		min-width: 89px;
		padding: 0 10px;
		height: 29px;
		background: #2dc05f;
		border-radius: 4px 4px 4px 4px;
		/* opacity: 1; */
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		/* color: #000; */
	}

	.h2-bottom-btn.disabled {
		background: #d9d9d9 !important;
	}

	.login-popup>.login-popup-h2>.h2-bottom>input {
		width: 100%;
		height: 100%;
		font-size: 20px;
		font-weight: bold;

	}

	.login-popup>.login-popup-h3 {
		margin-top: 8px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.login-popup>.login-popup-h3>.h3-left {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.login-popup>.login-popup-h3>.h3-right {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #fe4365;
	}

	.login-popup>.login-popup-h4 {
		display: flex;
		align-items: center;
	}

	.login-popup>.login-popup-h4>.h4-left {
		width: 16px;
		height: 16px;
		border-radius: 0px 0px 0px 0px;
		opacity: 0.5;
		border: 1px solid #707070;
		margin-right: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.login-popup>.login-popup-h4>.h4-right {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.login-popup>.login-popup-h5 {
		width: 300px;
		height: 52px;
		background: #fe4365;
		border-radius: 8px 8px 8px 8px;
		opacity: 1;
		font-size: 15px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		margin: 15px auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-popup>.login-popup-h6 {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		width: 392px;
		margin: 0 auto 0;
	}

	.password-popup {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translateX(-50%);
		width: 488px;
		/* height: 430px;  */
		background: #ffffff;
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		z-index: 1000;
		padding: 32px 40px 48px;
	}

	.password-popup>.password-popup-exit {
		position: absolute;
		top: 24px;
		left: 24px;
		width: 22px;
		height: 22px;
	}

	.password-popup>.password-popup-h1 {
		font-size: 20px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #000000;
		text-align: center;
	}

	.password-popup>.password-popup-input {
		width: 408px;
		height: 48px;
		background: #f9f9f9;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		/* color: #999999; */
		padding: 0 8px;
		margin-top: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.password-popup>.password-popup-input>input {
		background: #f9f9f9;
		flex: 1;
	}

	.password-popup>.password-popup-input>.yzm {
		/* min-width: 89px; */
		padding: 0 10px;
		height: 29px;
		background: #2dc05f;
		border-radius: 4px 4px 4px 4px;
		opacity: 1;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.yzm.disabled {
		background: #d9d9d9 !important;
	}

	.password-popup>.password-popup-btn {
		width: 300px;
		height: 52px;
		background: #fe4365;
		border-radius: 8px 8px 8px 8px;
		opacity: 1;
		font-size: 15px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 28px auto 0;
	}

	.over {
		position: fixed;
		width: 100%;
		height: 100%;
		opacity: 0.7;
		filter: alpha(opacity=70);
		top: 0;
		left: 0;
		z-index: 999;
		background-color: #111111;
	}

	.app-bottom-fixed {
		height: 400px;
	}

	.bottom-content {
		width: 1872px;
		display: flex;
		margin: 0 auto;
		// background: red;
		display: flex;
		justify-content: space-between;
	}

	.app-bottom {
		/* position: fixed;
  bottom: 0; */
		padding: 82px 0 51px 0;
		width: 100%;
		/* height: 364px; */
		background: #131838;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;

		// background: red;
	}

	.app-bottom .bottom-left .left-l1 {
		font-size: 32px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		align-items: center;
	}

	.app-bottom .bottom-left .left-l1 img {
		width: 40px;
		height: 40px;
	}

	.app-bottom .bottom-left .left-l2 {
		width: 456px;
		/* height: 66px; */
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		margin-top: 22px;
	}

	.app-bottom .bottom-center {
		margin-left: 129px;
		margin-right: 224px;
	}

	.app-bottom .bottom-center .center-c1 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	.app-bottom .bottom-center .center-c2 {
		font-size: 16px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		margin-top: 33px;
		margin-bottom: 34px;
	}

	.app-bottom .bottom-center .center-c3 {
		font-size: 16px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #ffffff;
		margin-top: 34px;
		margin-bottom: 64px;
	}

	.app-bottom .bottom-center .center-c4 {
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: rgb(154, 156, 170);
	}

	.app-bottom .bottom-right .right-r1 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.app-bottom .bottom-right .right-r2 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		margin-top: 33px;
		margin-block: 34px;
	}

	.app-bottom .bottom-right .right-r3 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
	}

	@media screen and (max-width: 1400px) {
		// .app-bottom {
		//   padding: 82px 140px 51px 143px !important;
		// }
	}

	@media screen and (max-width: 1100px) {
		// .nav-itemList {
		//   display: none;
		// }

		// .bottom-center {
		//   display: none;
		// }
	}
</style>