import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DemoView from '../views/DemoView.vue'
// 产品介绍
import Products from '../views/Products.vue'
// 意见反馈
import Opinion from '../views/Opinion.vue'
// 会员
import Member from '../views/Member.vue'
// 公司介绍
import Firm from '../views/Firm.vue'
// 账号安全
import User from '../views/User.vue'
// 通知消息
import Message from '../views/Message.vue'
// 订单
import Order from '../views/Order.vue'

// 付费协议
import XPay from '../views/XPay.vue'

// 隐私协议
import XPrivacy from '../views/XPrivacy.vue'

import XAbout from '../views/XAbout.vue'
import XMember from '../views/XMember.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 产品介绍
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  // 意见反馈
  {
    path: '/opinion',
    name: 'opinion',
    component: Opinion
  },
  // 会员中心
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  // 公司介绍
  {
    path: '/firm',
    name: 'firm',
    component: Firm
  },
  // 账号安全
  {
    path: '/user',
    name: 'user',
    component: User,
    children: [{
      path: 'order',
      component: Order
    }]
  },
  // 通知消息
  {
    path: '/message',
    name: 'message',
    component: Message
  },
  // 订单
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/XPrivacy',
    name: 'XPrivacy',
    component: XPrivacy
  },
  {
    path: '/XPay',
    name: 'XPay',
    component: XPay
  },
  {
    path: '/XAbout',
    name: 'XAbout',
    component: XAbout
  },
  {
    path: '/XMember',
    name: 'XMember',
    component: XMember
  },
 
  {
    path: '/demo',
    name: 'demo',
    component: DemoView
  }
  
]

const router = new VueRouter({
  routes
})

export default router
